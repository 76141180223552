export const skillInfo = [
  {
    perchant: 100,
    name: "Craftsmanship Quality",
    pathColor: "#8B4513", // Deep brown for the rich quality of woodwork
    desc: "Our template is crafted to highlight the precision and quality of your carpentry, ensuring your website reflects the excellence of your workmanship, enhancing brand trust and client satisfaction.",
  },
  {
    perchant: 100,
    name: "Design Flexibility",
    pathColor: "#C19A6B", // Light brown for the versatility of wood
    desc: "With an emphasis on customization, our template allows you to easily adjust the aesthetics to showcase your unique carpentry style and projects, ensuring your online presence is as unique as your creations.",
  },
  {
    perchant: 100,
    name: "Real-Time Project Updates",
    pathColor: "#4790A6", // Dark wood for the robustness of Firebase integration
    desc: "Incorporate the latest projects and testimonials directly on your site with our seamless Firebase integration, offering dynamic updates that keep your portfolio fresh and engaging.",
  },
  {
    perchant: 100,
    name: "Seamless Navigation",
    pathColor: "#556B2F", // Olive green for the ease and natural flow of user experience
    desc: "Designed for intuitive use, our template ensures visitors can effortlessly explore your carpentry services and projects, providing a smooth and engaging browsing experience.",
  },
];
