import projectOne from "../assets/images/projectOne.webp";
import projectTwo from "../assets/images/projectTwo.webp";
import projectThree from "../assets/images/projectThree.webp";
import projectFour from "../assets/images/projectFour.webp";
import projectFive from "../assets/images/projectFive.webp";
import projectSix from "../assets/images/projectSix.webp";

export const projectList = [
  {
    image: projectSix,
    projectName: "Sustainable Wood Interiors in Reykjavik",
    projectLocation: "Reykjavik, Iceland",
    imageHref: "https://www.skyfaze.com",
  },
  {
    image: projectFour,
    projectName: "Restauration Services in Vik",
    projectLocation: "Vik, Iceland",
    imageHref: "https://www.skyfaze.com/",
  },
  {
    image: projectThree,
    projectName: "Linkleberry.com - Revolutionary Link-In-Bio Tool",
    projectLocation: "Kopavagur, Iceland",
    imageHref: "https://www.skyfaze.com/",
  },
  {
    image: projectFive,
    projectName: "Art Project For University of Reaykjavik ",
    projectLocation: "Reykjavik, Iceland",
    imageHref: "https://www.skyfaze.com/",
  },
  {
    image: projectTwo,
    projectName: "Premium Garden Design in Kopavagour",
    projectLocation: "Kopavagur, Iceland",
    imageHref: "https://www.lagnabraedur.is",
  },
  {
    image: projectOne,
    projectName: "Roofing Project in Reykjavik",
    projectLocation: "Reykjavik, Iceland",
    imageHref: "https://www.westranga.com/",
  },
];
