import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Loader from "../../../Components/Shared/Loader/Loader";
import SummaryCard from "../../../Components/SummaryCard";
import useIsMounted from "../../../hooks/useIsMounted";
import {
  getSingleService,
  getUserOrder,
  getOrderList,
} from "../../../Services/DashboardServices";
import Sidebar from "../Sidebar/Sidebar";

const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const [dateFilter, setDateFilter] = useState("all");

  const [orderSummary, setOrderSummary] = useState([]);
  const isMounted = useIsMounted();

  useEffect(() => {
    if (isMounted) {
      getOrderList().then((data) => {
        data.reverse();
        setOrders(data);
      });
    }
  }, [isMounted]);

  useEffect(() => {
    if (isMounted) {
      getOrderList().then((data) => {
        const filteredData = data.filter((order) => {
          if (dateFilter === "all") {
            return true;
          }

          const orderDate = new Date(order.createdAt.seconds * 1000); // Convert Firestore timestamp to JS Date
          const today = new Date();
          today.setHours(0, 0, 0, 0);

          if (dateFilter === "today") {
            return orderDate >= today;
          }

          const thisWeekStart = new Date(today);
          thisWeekStart.setDate(today.getDate() - today.getDay());

          if (dateFilter === "thisWeek") {
            return orderDate >= thisWeekStart;
          }

          const thisMonthStart = new Date(
            today.getFullYear(),
            today.getMonth(),
            1,
          );

          if (dateFilter === "thisMonth") {
            return orderDate >= thisMonthStart;
          }

          return false;
        });

        setOrders(filteredData.reverse());
      });
    }
  }, [isMounted, dateFilter]);

  useEffect(() => {
    const fetchDetailedOrders = async () => {
      try {
        const fetchedOrders = await getOrderList(); // Assuming this fetches all orders you need
        const ordersWithDetails = await Promise.all(
          fetchedOrders.map(async (order) => {
            const serviceDetails = await getSingleService(order.serviceId);
            return {
              ...order, // Spread the original order to keep its properties
              serviceDetails, // Add the fetched service details
              orderId: order.id, // Ensure orderId is explicitly set
            };
          }),
        );
        if (isMounted) {
          setOrders(ordersWithDetails.reverse()); // Use the detailed orders to update state
        }
      } catch (error) {
        console.error("Error fetching detailed orders:", error);
      }
    };

    if (isMounted) {
      fetchDetailedOrders();
    }
  }, [isMounted]);

  useEffect(() => {
    if (isMounted) {
      const totalOrder = orders.length;
      const websiteVisits = 1234;
      const pageClicks = 5678;
      const activeUsers = 42;

      setOrderSummary([
        {
          id: 1,
          name: "Total Orders",
          value: totalOrder,
          color: "dark",
          pathColor: "#343A40",
        },
        {
          id: 2,
          name: "Website Visits",
          value: websiteVisits,
          color: "info",
          pathColor: "#17a2b8",
        },
        {
          id: 3,
          name: "Page Clicks",
          value: pageClicks,
          color: "primary",
          pathColor: "#007bff",
        },
        {
          id: 4,
          name: "Active Users",
          value: activeUsers,
          color: "success",
          pathColor: "#28a745",
        },
      ]);
    }
  }, [orders, isMounted]);

  return (
    <section>
      <div className="row mr-0">
        <div className="col-2">
          <Sidebar></Sidebar>
        </div>

        <div className="mb-3"></div>

        <div className="col-10 mt-5 d-flex flex-column align-items-center">
          <div className="row">
            {orderSummary.map((each) => (
              <SummaryCard key={each.id} info={each} />
            ))}
          </div>
          <div className="col-10 mt-5 d-flex flex-column align-items-lef">
            <h2>Orders</h2>
            <div className="row container  justify-content-left mb-4 mt-3">
              {" "}
              <select
                className="form-select"
                value={dateFilter}
                onChange={(e) => setDateFilter(e.target.value)}
              >
                <option value="all">All Time</option>
                <option value="today">Today</option>
                <option value="thisWeek">This Week</option>
                <option value="thisMonth">This Month</option>
              </select>
            </div>

            {orders.length ? (
              <Table
                bordered
                striped
                hover
                responsive
                size="sm"
                variant="light"
                className="orderTable"
              >
                <thead>
                  <tr>
                    <th>Email ID</th>
                    <th>Purchase ID</th>
                    <th>Service</th>
                    <th>Paid With</th>
                    <th>Date Created</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((each) => (
                    <tr key={each._id}>
                      <td>{each.email}</td>
                      <td>{each.orderId}</td>
                      <td>{each.serviceName}</td>
                      <td>Credit Card</td>
                      <td>
                        {each.createdAt
                          ? new Date(
                              each.createdAt.seconds * 1000,
                            ).toLocaleDateString("en-US")
                          : "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OrderList;
