import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

const TopBanner = () => {
  return (
    <section className="mt-5">
      <div className=" container headerr ">
        <div className="row align-items-center ">
          <div className="col-md-6 ">
            <div className="container ">
              <h1 className="cornerstone text-dangen">
                Premier Carpentry Services
                <br />
                Crafted in Reykjavik
              </h1>

              <p className="mt-5">
                {" "}
                <span>
                  {" "}
                  <FontAwesomeIcon
                    className="mr-1"
                    color="green"
                    icon={faCheck}
                  />{" "}
                  Exclusive Offer
                </span>{" "}
                <span className="ml-md-4 d-md-inline d-block">
                  {" "}
                  <FontAwesomeIcon
                    className="mr-2"
                    color="green"
                    icon={faCheck}
                  />
                  Premium Website
                </span>{" "}
              </p>
              <p className="mt-4">
                Skyfaze's React template, now with an advanced carpenters theme,
                is slick, modern, and ultra-fast, designed specifically for
                carpentry businesses. No hosting or future fees. It's an
                all-in-one solution - ideal for
                showcasing craftsmanship with Custom React Development, Free
                Hosting Forever, and dynamic updates via Firebase. Perfect for
                carpenters aiming for a standout digital presence.
              </p>
              <Link to="/services" className="btn btn-danger mt-4">
                Get Started
              </Link>
            </div>
          </div>
          <div className="col-md-6 mt-md-0 mt-5">
            <div className="text-center">
              <img
                className="HeaderImage"
                style={{ height: "600px" }}
                src="https://res.cloudinary.com/djo4u0ywn/image/upload/v1712057757/profilephotolinkleberry/epqzrha0tvew5lrs5tvz.webp"
                alt="Exclusive React Development Offer"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopBanner;
