import React from "react";
import { Card } from "react-bootstrap";

const SummaryCard = ({ info }) => {
  return (
    <div className="d-flex justify-content-center mb-5 mx-3">
      <Card className="shadow" style={{ width: "18rem", textAlign: "center" }}>
        <Card.Body>
          <div className="d-flex flex-column justify-content-center align-items-center">
            {/* Title */}
            <div className="mb-2">
              <b>{info.name}</b>
            </div>

            {/* Value */}
            <h1 className={`text-${info.color}`} style={{ margin: 0 }}>
              {info.value}
            </h1>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SummaryCard;
