import React from "react";
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../Styles/ServiceCard.css";

const ServiceCard = ({ serviceInfo }) => {
  const { serviceName, serviceImg, serviceDesc, servicePrice, id } =
    serviceInfo;

  const renderPriceOrButton = () => {
    if (servicePrice === 0) {
      return (
        <Button as={Link} to="/contact" className="service-button">
          Contact Us
        </Button>
      );
    } else {
      return (
        <>
          <Card.Title className="service-price"> $ {servicePrice} </Card.Title>
          <Button
            as={Link}
            to={`dashboard/book/${id}`}
            variant="success"
            className="service-button"
          >
            Book Now
          </Button>
        </>
      );
    }
  };

  return (
    <div className="services col-md-4 d-flex justify-content-center ">
      <Card
        border="light"
        className="shadow"
        style={{ width: "18rem", textDecoration: "none" }}
      >
        <Card.Title className="text-center cutom-text service-title">
          {serviceName}
        </Card.Title>
        <div className="text-center">
          <Card.Img
            className="service-img"
            alt="service"
            variant="top"
            style={{ borderRadius: "10px", width: "150px" }}
            src={serviceImg}
          />
        </div>
        <Card.Body>
          {renderPriceOrButton()}
          <Card.Text className="text-secondary">{serviceDesc}</Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ServiceCard;
