import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import { UserContext } from "../../App";
import loginImg from "../../assets/images/loginImg.webp";
import Loader from "../../Components/Shared/Loader/Loader";
import {
  checkAdmin,
  getToken,
  googleSignIn,
  signIn,
  registerUser,
} from "../../Services/AuthServices";
import "../../Styles/LoginCss.css";

const Login = () => {
  const [loggedInUser, setLoggedInUser] = useContext(UserContext);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [loginAsAdmin, setLoginAsAdmin] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { email } = loggedInUser;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const handleRegister = (data) => {
    if (data.email && data.password) {
      setIsLoading(true);
      registerUser(data.email, data.password)
        .then((res) => {
          // Handle successful registration
          setIsLoading(false);
          // Optionally log the user in immediately after registration
          helper(res.user);
        })
        .catch((error) => {
          // Handle registration errors
          setIsLoading(false);
          console.error("Registration error:", error.message);
        });
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 10,
      left: 10,
      behavior: "smooth",
    });
  }, []);

  let history = useHistory();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };

  const [mode, setMode] = useState("login"); // Add this line
  const toggleMode = () => {
    setMode((prevMode) => (prevMode === "login" ? "register" : "login"));
  };
  const handleGoogleSignIn = () => {
    googleSignIn().then(function (result) {
      helper(result.user);
    });
  };
  const onSubmit = (data) => {
    mode === "login" ? signInUser(data) : handleRegister(data);
  };

  // Separate signIn logic for clarity
  const signInUser = (data) => {
    if (data.email && data.password) {
      setIsLoading(true);
      signIn(data).then((res) => {
        helper(res.user);
        setIsLoading(false);
      });
    }
  };

  const helper = async ({ displayName, email, photoURL }) => {
    const isAdmin = await checkAdmin(); // Check if the user is an admin
    const token = await getToken();
    if (token) {
      localStorage.setItem("token", token);
      const signedInUser = {
        name: displayName,
        email,
        photoURL,
        isSignedIn: true,
        isAdmin,
      };
      setLoggedInUser(signedInUser); // Now also includes isAdmin
      history.replace(from);
    } else {
      console.log("Failed to retrieve user token.");
    }
  };

  // const handleChange = (e) => {
  //     if (e.target.id === 'admin') setLoginAsAdmin(true);
  //     if (e.target.id === 'user') setLoginAsAdmin(false);
  // }

  useEffect(() => {
    checkAdmin(email).then((data) => {
      const newLoggedInUser = { ...loggedInUser };
      newLoggedInUser.isAdmin = data;
      setLoggedInUser(newLoggedInUser);
    });
  }, [email]);

  return (
    <div className="row justify-content-center mx-0 align-items-center mb-5">
      <div className="col-md-6 align-items-center d-flex flex-column">
        <h3>Welcome,</h3>
        <p className="text-secondary">Sign in or register to continue</p>

        <button onClick={toggleMode} className="btn btn-link">
          {mode === "login"
            ? "Need an account? Register"
            : "Have an account? Login"}
        </button>

        <form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
          <input
            className="form-control"
            placeholder="Email"
            {...register("email", { required: true })}
          />
          {errors.email && (
            <span className="text-dangen">Email is required</span>
          )}

          <input
            className="form-control mt-3"
            type="password"
            placeholder="Password"
            {...register("password", { required: true })}
          />
          {errors.password && (
            <span className="text-dangen">Password is required</span>
          )}

          <input
            className="btn btn-outline-danger btn-block mt-3"
            type="submit"
            value={mode === "login" ? "Login" : "Register"}
          />
          <div
            className={`justify-content-center mt-3 ${
              isLoading ? "d-flex" : "d-none"
            }`}
          >
            {" "}
            <Loader />
          </div>
        </form>

        <p className="mt-3 text-secondary">---------- or ----------</p>
        <div onClick={handleGoogleSignIn} className="google-btn mt-1">
          <div className="google-icon-wrapper">
            <svg
              xmlns="https://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 48 48"
              aria-hidden="true"
              jsname="jjf7Ff"
            >
              <path
                fill="#4285F4"
                d="M45.12 24.5c0-1.56-.14-3.06-.4-4.5H24v8.51h11.84c-.51 2.75-2.06 5.08-4.39 6.64v5.52h7.11c4.16-3.83 6.56-9.47 6.56-16.17z"
              ></path>
              <path
                fill="#34A853"
                d="M24 46c5.94 0 10.92-1.97 14.56-5.33l-7.11-5.52c-1.97 1.32-4.49 2.1-7.45 2.1-5.73 0-10.58-3.87-12.31-9.07H4.34v5.7C7.96 41.07 15.4 46 24 46z"
              ></path>
              <path
                fill="#FBBC05"
                d="M11.69 28.18C11.25 26.86 11 25.45 11 24s.25-2.86.69-4.18v-5.7H4.34C2.85 17.09 2 20.45 2 24c0 3.55.85 6.91 2.34 9.88l7.35-5.7z"
              ></path>
              <path
                fill="#EA4335"
                d="M24 10.75c3.23 0 6.13 1.11 8.41 3.29l6.31-6.31C34.91 4.18 29.93 2 24 2 15.4 2 7.96 6.93 4.34 14.12l7.35 5.7c1.73-5.2 6.58-9.07 12.31-9.07z"
              ></path>
              <path fill="none" d="M2 2h44v44H2z"></path>
            </svg>
          </div>
          <p className="btn-text">
            <b>Sign in with Google</b>
          </p>
        </div>
      </div>
      <div className="col-md-6 d-md-block d-none">
        <img className="w-75" src={loginImg} alt="skyfaze template login" />
      </div>
    </div>
  );
};

export default Login;
