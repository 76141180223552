import React, { useEffect } from "react";
import { Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { scrollTo } from "../../src/tools/scroll";

// Updated teamMembers array with placeholder profile images
const teamMembers = [
  {
    name: "John Doe",
    specialization: "Master Carpenter",
    phone: "+3547891992",
    email: "info@skyfaze.com",
    image:
      "https://res.cloudinary.com/djo4u0ywn/image/upload/v1712049863/profilephotolinkleberry/qfr4satjtshp3x8wzfhm.webp",
  },
  {
    name: "Jack Smith",
    specialization: "Design Consultant",
    phone: "+3547891992",
    email: "info@skyfaze.com",
    image:
      "https://res.cloudinary.com/djo4u0ywn/image/upload/v1712049791/profilephotolinkleberry/eywhucwzsmxjh6ytxjne.webp",
  },
  {
    name: "Erik Johnson",
    specialization: "Project Manager",
    phone: "+3547891992",
    email: "info@skyfaze.com",
    image:
      "https://res.cloudinary.com/djo4u0ywn/image/upload/v1712049811/profilephotolinkleberry/jvjj8lvetghdgydljtjj.webp",
  },
  {
    name: "Borat Connor",
    specialization: "Finishing Expert",
    phone: "+3547891992",
    email: "info@skyfaze.com",
    image:
      "https://res.cloudinary.com/djo4u0ywn/image/upload/v1712049881/profilephotolinkleberry/vvkgeb09paskz6t0ncmj.webp",
  },
];

const Members = () => {
  useEffect(() => {
    scrollTo(10, 10);
  }, []);

  return (
    <section className="my-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 mt-3">
            <h2 className="text-center">
              <Badge className="badges pl-4 pr-4 p-2" variant="danger" pill>
                Meet Our Team
              </Badge>
            </h2>
          </div>
          {teamMembers.map((member) => (
            <div className="col-md-3 col-sm-6 mb-5" key={member.name}>
              <div className="team-member text-center">
                {/* Profile Image with Framer Motion */}
                <motion.img
                  src={member.image}
                  alt={member.name}
                  className="profile-image"
                  initial={{ y: 0, scale: 1 }}
                  whileHover={{
                    scale: 1.05,
                    y: -10,
                    filter: "brightness(1.1)",
                    transition: { type: "spring", stiffness: 300 },
                  }}
                  style={{
                    borderRadius: "50%",
                    marginBottom: "15px",
                    width: "200px",
                    marginTop: "5rem",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    transition: "box-shadow 0.3s ease-in-out",
                  }}
                />

                <h3>{member.name}</h3>
                <p>{member.specialization}</p>
                <a href={`tel:${member.phone}`} className="mr-2">
                  <FontAwesomeIcon className="contact-icons" icon={faPhone} />
                </a>
                <a href={`mailto:${member.email}`}>
                  <FontAwesomeIcon
                    className="contact-icons"
                    style={{ marginLeft: "1rem" }}
                    icon={faEnvelope}
                  />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Members;
