import React, { useState, useEffect, useRef } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const Progress = ({ perchantage }) => {
  const { desc, perchant, name, pathColor } = perchantage;
  const [value, setValue] = useState(0);
  const ref = useRef(null);

  const handleScroll = () => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
      const visibleHeight = isVisible
        ? Math.min(window.innerHeight, rect.bottom) - Math.max(0, rect.top)
        : 0;
      const percentageVisible = (visibleHeight / rect.height) * 100;
      setValue((percentageVisible / 100) * perchant);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    handleScroll(); // Initial check
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [perchant]);

  return (
    <div ref={ref} className="col-md-6 p-3">
      <div className="row">
        <div className="col-4">
          <CircularProgressbar
            value={value}
            text={`${Math.round(value)}%`}
            styles={buildStyles({
              pathColor: pathColor,
              textColor: pathColor,
              transition: "stroke-dashoffset 0.7s ease 0s",
            })}
          />
        </div>
        <div className="col-8">
          <h5>{name}</h5>
          <p className="text-secondary">{desc}</p>
        </div>
      </div>
    </div>
  );
};

export default Progress;
