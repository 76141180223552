import React, { useContext, useEffect, useState } from "react";
import { SideBarContext } from "../../../App";
import Loader from "../../../Components/Shared/Loader/Loader";
import {
  getSkyFazeServices,
  getAdminReviews,
  approveReview,
} from "../../../Services/UserServices";
import {
  manageService,
  removeReview,
} from "../../../Services/DashboardServices";

import Sidebar from "../Sidebar/Sidebar";
import ManageServiceCard from "./ManageServiceCard";
import TestimonialCardManage from "../../../Components/TestimonialCard/TestimonialCardManage";
import toast from "react-hot-toast";

const ManageServices = () => {
  const [services, setServices] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [sideBarInfo] = useContext(SideBarContext);

  useEffect(() => {
    const fetchServicesAndReviews = async () => {
      const fetchedServices = await getSkyFazeServices();
      setServices(fetchedServices);
      const fetchedReviews = await getAdminReviews();
      setReviews(fetchedReviews);
    };

    fetchServicesAndReviews();
  }, []);
  const handleDeleteReview = async (id) => {
    // Show confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this review?",
    );
    if (!isConfirmed) {
      return; // Early return if user cancels
    }

    try {
      await removeReview(id); // Call your service function to delete the review
      toast.success("Review deleted successfully!");
      setReviews((reviews) => reviews.filter((review) => review.id !== id)); // Update local state to reflect deletion
    } catch (error) {
      toast.error("Failed to delete review.");
      console.error("Error deleting review:", error);
    }
  };

  const handleDeleteService = async (id) => {
    // Show confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this service?",
    );
    if (!isConfirmed) {
      return; // Early return if user cancels
    }

    try {
      await manageService(id); // Deletes the service
      setServices((services) =>
        services.filter((service) => service.id !== id),
      ); // Updates state
      toast.success("Service deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete service.");
      console.error("Error deleting service:", error);
    }
  };

  const handleApproveReview = async (id) => {
    try {
      await approveReview(id);
      toast.success("Review approved successfully!");
      setReviews(
        reviews.map((review) =>
          review.id === id ? { ...review, isApproved: true } : review,
        ),
      );
    } catch (error) {
      toast.error("Failed to approve review.");
      console.error("Error approving review:", error);
    }
  };

  return (
    <section>
      <div className="row mr-0">
        <div
          className={`col-md-2 col-sm-6 col-12 d-md-block d-${sideBarInfo.isOpen ? "block" : "none"}`}
        >
          <Sidebar></Sidebar>
        </div>
        <div className="col-md-10 col-sm-12 col-12">
          <div className="container mt-5">
            <h2>Manage Services</h2>
            <br></br>
            <div className="row justify-content-center">
              {services.length ? (
                services.map((service) => (
                  <ManageServiceCard
                    key={service.id}
                    serviceInfo={service}
                    onDeleteService={() => handleDeleteService(service.id)}
                  />
                ))
              ) : (
                <Loader />
              )}
            </div>

            <h2 className="mt-5">Manage Reviews</h2>
            <br></br>
            <div className="row justify-content-center">
              {reviews.length ? (
                reviews.map((review) => (
                  <div
                    key={review.id}
                    className={`review-container ${!review.isApproved ? "grayscale" : ""}`}
                  >
                    <TestimonialCardManage testimonialInfo={review} />
                    <div className="d-flex justify-content-between">
                      {!review.isApproved && (
                        <button
                          className="btn-approve btn btn-success"
                          onClick={() => handleApproveReview(review.id)}
                        >
                          Approve Review
                        </button>
                      )}
                      <button
                        className="btn-delete btn btn-danger"
                        onClick={() => handleDeleteReview(review.id)}
                      >
                        Delete Review
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ManageServices;
