import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore"; // Import Firestore
import firebaseConfig from "./../firebase.config";

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

const db = firebase.firestore();

export const checkAdmin = async () => {
  const currentUser = firebase.auth().currentUser;
  if (!currentUser) {
    console.log("No user signed in");
    return false;
  }
  const userRef = db.collection("users").doc(currentUser.uid);
  const doc = await userRef.get();
  if (!doc.exists) {
    console.log("No such document!");
    return false;
  } else {
    return doc.data().isAdmin === true;
  }
};

export const logout = () => {
  return firebase.auth().signOut();
};
export const signIn = (data) => {
  return firebase.auth().signInWithEmailAndPassword(data.email, data.password);
};

export const registerUser = (email, password) => {
  return firebase.auth().createUserWithEmailAndPassword(email, password);
};

export const googleSignIn = () => {
  var provider = new firebase.auth.GoogleAuthProvider();
  return firebase.auth().signInWithPopup(provider);
};

export const getCurrentUser = () => {
  return firebase.auth().currentUser;
};

export const getToken = async () => {
  const user = firebase.auth().currentUser;
  if (!user) {
    console.log("No user signed in");
    return null;
  }
  const token = await user.getIdToken();
  return token;
};
