import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router";
import { UserContext } from "../../../App";
import {
  addOrder,
  getSingleService,
} from "../../../Services/DashboardServices";
import Sidebar from "../Sidebar/Sidebar";

const Book = () => {
  const [{ name, email }] = useContext(UserContext);
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation(); // To access query parameters

  // Order information state
  const [orderInfo, setOrderInfo] = useState({
    fullname: name,
    email: email,
    service: "",
    price: "",
    status: "pending",
    color: "danger",
    description: "",
    image: "",
  });

  // Extract query parameters to detect payment success
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paymentSuccess = queryParams.get("paymentSuccess");

    if (paymentSuccess) {
      finalizeOrder();
    }
  }, [location.search]);

  const finalizeOrder = async () => {
    const result = await addOrder(orderInfo);

    if (result.success) {
      console.log("Order added with ID: ", result.id);
      history.push("/dashboard/bookingList");
    } else {
      console.error("Error adding order: ", result.error);
    }
  };
  const handlePayment = async () => {
    // Assuming 'id' holds the serviceId from useParams() or another state.
    const paymentData = {
      amount: orderInfo.price,
      currency: "USD",
      serviceId: id, // Include the service ID here
      serviceName: orderInfo.service, // Correctly reference serviceName from orderInfo
    };

    try {
      const response = await fetch("/api/create-payment", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(paymentData),
      });

      if (!response.ok) {
        throw new Error(
          "Server responded with an error: " + response.statusText,
        );
      }

      const data = await response.json();

      if (data.redirect_url) {
        window.location.href = data.redirect_url; // Redirect happens here
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    getSingleService(id)
      .then((data) => {
        if (data) {
          const newOrderInfo = { ...orderInfo };
          newOrderInfo.price = data.servicePrice;
          newOrderInfo.service = data.serviceName;
          newOrderInfo.description = data.serviceDesc;
          newOrderInfo.image = data.serviceImg;
          setOrderInfo(newOrderInfo);
        } else {
          console.log("No service found for ID:", id);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch service:", error);
      });
  }, [id]);

  //checking if payment clear and placing order

  const handleBlur = (event) => {
    const { name, value } = event.target;
    setOrderInfo((prevOrderInfo) => ({
      ...prevOrderInfo,
      [name]: value,
    }));
  };

  return (
    <section>
      <div className="row mr-0">
        <div className="col-md-2 col-sm-6 col-12">
          <Sidebar></Sidebar>
        </div>
        <div className="col-md-10 col-sm-12 col-12 mt-5">
          <div className="col-md-6">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <input
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                    className="form-control mb-4 border-0 bg-light"
                    defaultValue={name}
                    name="fullname"
                  />
                </div>
                <div className="col-md-12">
                  <input
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                    className="form-control mb-4 border-0 bg-light"
                    value={email}
                    name="email"
                  />
                </div>
                <div className="col-md-12">
                  <input
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                    className="form-control mb-4 border-0 bg-light"
                    defaultValue={orderInfo.service}
                    name="service"
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <h6>Your service charge will be ${orderInfo.price}</h6>
                </div>
              </div>
            </form>
            <div className="row">
              <div className="col-md-12">
                <button className="btn btn-primary" onClick={handlePayment}>
                  Pay with Rapyd
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Book;
