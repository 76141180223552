import React, { useEffect } from "react";
import { Badge } from "react-bootstrap";
import Progress from "../../../Components/Progress";
import { skillInfo } from "../../../Data/skillInfo";
import { scrollTo } from "../../../tools/scroll";
import Members from "../../../Components/Members";

const About = () => {
  useEffect(() => {
    scrollTo(10, 10);
  }, []);

  return (
    <>
      <section className="my-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 ">
              <h2 className="text-center">
                <Badge className=" badges pl-4 pr-4 p-2" variant="danger" pill>
                  About Our Template
                </Badge>
              </h2>
            </div>
            <div className="col-12 mb-5"></div>
            <div className="col-12 mt-2 mb-7">
              <div className="row align-items-center about-section">
                <div className="col-md-6 position-relative">
                  <div className="image-aboutus-section">
                    <img
                      src="https://res.cloudinary.com/djo4u0ywn/image/upload/v1712057526/profilephotolinkleberry/ektwyqyab02p8xvdbjvc.webp"
                      alt="About Us"
                      className="img-fluid about-image"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <p className="about-text ">
                    We're offering an unparalleled opportunity to develop a
                    high-end website powered by Next.js, complete with a full
                    suite of features tailored to your business needs. For just
                    80,000 ISK, you'll receive:
                  </p>
                  <ul>
                    <li>
                      Fully Integrated User and Admin Dashboards: Seamless site
                      management, synced with Firebase.
                    </li>
                    <li>
                      Top Performance: Lightning-fast loading times and optimal
                      user experience.
                    </li>
                    <li>
                      Dynamic Framer Motion Animations: Engage visitors with
                      beautifully animated interfaces.
                    </li>
                    <li>
                      Comprehensive Website Pages: Home, About, Services,
                      Contact, Terms & Conditions, Reviews, and more.
                    </li>
                    <li>
                      Free Hosting on Vercel: Benefit from reliable hosting with
                      top-tier performance.
                    </li>
                  </ul>
                  <p className="about-text">
                    <strong>How It Works:</strong>
                  </p>
                  <ul>
                    <li>
                      Initial Payment: Pay half upfront, providing logo and
                      company description.
                    </li>
                    <li>
                      Consultation Call: Finalize details and start customizing
                      the template.
                    </li>
                    <li>
                      Setup and Deployment: Create a Google account for your
                      business and deploy your site, handling domain
                      registration for you.
                    </li>
                    <li>
                      Final Delivery: After going live, pay the remaining
                      balance. Receive a Google business account pre-configured
                      with your details.
                    </li>
                  </ul>
                  <p className="about-text">
                    Transform your online presence with a custom-developed
                    Next.js website. Contact us to begin this journey to digital
                    excellence!
                  </p>
                </div>
                <Members />
              </div>
            </div>
            {skillInfo.map((each) => (
              <Progress key={each.name} perchantage={each} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
