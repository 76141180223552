import firebase from "firebase/app";
import "firebase/firestore";
import firebaseConfig from "./../firebase.config";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const getOrderList = async () => {
  const ordersCol = db.collection("orders");
  const ordersSnapshot = await ordersCol.get();
  const ordersList = ordersSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return ordersList;
};

// Function to change the status of an order in Firestore
export const changeOrderStatus = async (id, eventValue) => {
  const orderRef = db.collection("orders").doc(id);
  await orderRef.update({ status: eventValue }); // Assuming 'eventValue' contains the new status
  return { success: true, message: "Order status updated successfully." };
};

export const addOrder = async (orderData) => {
  try {
    const ordersCol = db.collection("orders");
    const orderWithTimestamp = {
      ...orderData,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    const docRef = await ordersCol.add(orderWithTimestamp);
    return { success: true, id: docRef.id };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

const db = firebase.firestore();

// Function to get all services
export const getServices = async () => {
  const servicesCol = db.collection("services");
  const serviceSnapshot = await servicesCol.get();
  const serviceList = serviceSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return serviceList;
};

// Function to update a specific service
export const updateService = async (id, serviceData) => {
  const serviceRef = db.collection("services").doc(id);
  await serviceRef.update(serviceData);
};

// Function to add a new service
export const addService = async (serviceData) => {
  const servicesCol = db.collection("services");
  const docRef = await servicesCol.add(serviceData);
  return docRef.id; // Returns the new document ID
};

export const manageService = async (id) => {
  try {
    const serviceRef = db.collection("services").doc(id);
    await serviceRef.delete();
    return { success: true, id };
  } catch (error) {
    throw error;
  }
};

export const getUserOrder = async (email) => {
  const ordersCol = db.collection("orders");
  const querySnapshot = await ordersCol.where("email", "==", email).get();
  const orders = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return orders;
};

export const addReview = async (reviewData) => {
  try {
    const reviewWithApproval = { ...reviewData, isApproved: false };
    const reviewsCol = db.collection("reviews");
    const docRef = await reviewsCol.add(reviewWithApproval);
    console.log("Review added with ID: ", docRef.id, "");
    return { success: true, id: docRef.id };
  } catch (error) {
    console.error("Error adding review: ", error);
    return { success: false, error: error.message };
  }
};

export const removeReview = async (reviewId) => {
  try {
    const reviewsCol = db.collection("reviews");
    await reviewsCol.doc(reviewId).delete();
    console.log("Review deleted with ID:", reviewId);
    return { success: true, id: reviewId };
  } catch (error) {
    console.error("Error removing review: ", error);
    return { success: false, error: error.message };
  }
};

export const getSingleService = async (id) => {
  try {
    const serviceRef = db.collection("services").doc(id);
    const doc = await serviceRef.get();
    if (doc.exists) {
      return { id: doc.id, ...doc.data() };
    } else {
      return null;
    }
  } catch (error) {
    return { error: error.message };
  }
};

export const makeAdmin = async (email) => {
  try {
    const userRef = db.collection("users").doc(email);
    await userRef.set(
      {
        isAdmin: true,
      },
      { merge: true },
    );

    return { success: true, message: "User successfully made an admin" };
  } catch (error) {
    console.error("Error making user admin: ", error);
    return { success: false, message: error.message };
  }
};
