import React, { useContext, useEffect, useState, useRef } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { UserContext } from "../../../App";

const TopBar = () => {
  const [{ isSignedIn, name, photoURL, isAdmin }] = useContext(UserContext);
  const [offset, setOffset] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const navbarRef = useRef(null); // Ref for the navbar

  useEffect(() => {
    const handleScroll = () => {
      setOffset(window.pageYOffset);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const navbarHeight = navbarRef.current ? navbarRef.current.offsetHeight : 0;
    document.body.style.paddingTop = `${navbarHeight}px`;
  }, [offset]); // Re-calculate when offset changes, or add more dependencies as needed

  const toggleTheme = () => {
    document.body.classList.toggle("dark-theme");
    setIsDarkTheme((prevState) => !prevState);
  };

  return (
    <Navbar
      ref={navbarRef} // Attach the ref to the Navbar
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)}
      expand="lg"
      className={`fixed-top ${offset > 5 ? "navbarCustom" : "navbarCustom1"}`}
      style={{
        transition: "all 0.3s ease-out",
      }}
    >
      <Navbar.Brand as={Link} to="/" onClick={() => setExpanded(false)}>
        <svg
          alt="logo skyfaze"
          aria-label="skyfaze logo"
          class="logo"
          width="52"
          height="52 "
          viewBox="0 0 296.7 184.1 "
        >
          <defs>
            <clipPath id=":R2sm:monogram-clip">
              <path d="m93.4,29.2h-33c-1.3,0-2.9-1.1-3.8-2.2-3.9-4.8-11.1-6-15.8-2-1.2,1-2.3,3.5-1.9,4.6.7,1.6,2.9,3.4,4.5,3.6,6.7.7,13.6.5,20.3,1.2,5.4.5,10.8,1.2,15.9,2.9,9.1,3,14.5,9.8,15.9,19.2,1.4,9.6-2.8,17.4-10.5,22.6-13.6,9.2-28.9,11.4-45,9.7-11-1.2-21.4-3.7-30.4-10.4-5.8-4.5-8.5-10.4-8.9-17.9h37.2c4.5,6.5,8.8,8.3,15.5,5.2,2.2-1,4.9-3.9,5-5.9,0-3.2-3.2-4.6-6.5-4.6-6.8,0-13.7.6-20.5-.2-6.1-.7-12.5-2-17.8-4.8C-3.4,41.3-4.3,20.4,12,10.4c7.1-4.4,15.6-7,23.8-8.9,12.6-2.9,25.3-1.3,37.4,3.4,11.1,4.3,18.9,11.2,20.2,24.3Z"></path>
              <path
                class="0"
                d="m107.1.5h30.5v33.6c.3.2.7.3,1,.5,2.8-4.6,5.5-9.2,8.3-13.7,3.6-6,7-12,10.8-17.9.8-1.3,2.7-2.6,4.1-2.6,10.7-.2,21.3,0,32.8,0-3.3,6-6.4,11.6-9.4,17.1-4.5,8.1-8.8,16.3-13.5,24.2-1.6,2.7-1.4,4.5,0,7.1,7.6,13.1,14.9,26.4,22.9,40.6h-16.9c-6,0-13,1.9-17.7-.7-4.5-2.4-6.6-9.4-9.7-14.4-3.9-6.3-7.7-12.6-12.3-20.2v35h-30.8V.5h-.1Z"
              ></path>
              <path
                class="st0"
                d="m263.7,89h-34c0-7.7-.3-15.1,0-22.6.3-5.6-1.4-10.2-4.1-14.9-9.1-15.9-17.9-31.9-26.8-47.9-.5-.8-.7-1.8-1.4-3.3,11.5,0,22.6,0,33.7,0,1.1,0,2.5,1.9,3.3,3.2,4.1,7.2,8.1,14.6,12.5,22.5,4.5-8.1,8.5-15.5,12.8-22.6.9-1.5,3-3,4.6-3.1,10.5-.3,21,0,32.4,0-4.4,8-8.6,15.6-12.8,23.1-5.4,9.6-10.7,19.3-16.3,28.9-2.9,5-4.4,10.1-4.1,16,.4,6.8,0,13.6,0,20.8h.2Z"
              ></path>
              <path
                class="st0"
                d="m295.7,175.9v7.9h-63.9v-71.1h63.8v7.6h-55v22.5h48.4v7.3h-9.9c-11.7,0-23.3,0-35,0-3.1,0-3.9,1-3.9,3.9.2,7.1,0,14.2,0,21.8h55.4Z"
              ></path>
              <path
                class="st0"
                d="m213.6,176.2v7.6h-64.1c16-21,31.9-41.8,48.1-63.2h-48.1v-7.9h64.3c-16.3,21.2-32.2,41.9-48.8,63.5h48.6,0Z"
              ></path>
              <path
                class="st0"
                d="m63.9,112.7v7.6H8.9v22.6h44.5v8H8.6v32.9H0v-71.1h63.9Z"
              ></path>
              <path
                class="st0"
                d="m143.4,184c-3.2,0-5.8.2-8.3,0-1-.2-2.3-1.1-2.8-2.1-7.4-12.9-14.6-25.9-22-38.9-2.9-5.2-5.8-10.4-9.1-16.3-2.1,3.5-3.9,6.4-5.6,9.4-8.4,15-16.7,29.9-24.9,45-1.2,2.2-2.6,3.1-5.1,2.9-2-.2-4,0-6.7,0,14.1-25.3,27.9-50,42.2-75.5,14.2,25.3,28,50,42.2,75.5h0Z"
              ></path>
              <path
                class="st0"
                d="m101.1,184c-3.5,0-5.7-2.3-5.7-5.8s2.1-5.5,5.5-5.5,5.8,2.2,5.8,5.7-2.6,5.6-5.6,5.6h0Z"
              ></path>
            </clipPath>
          </defs>
          <rect
            clip-path="url(#:R2sm:monogram-clip)"
            width="100%"
            height="100%"
          ></rect>
          <g clip-path="url(#:R2sm:monogram-clip)">
            <rect
              class="Monogram_highlight__ly_rQ"
              width="100%"
              height="100%"
            ></rect>
          </g>
        </svg>
      </Navbar.Brand>
      <div className="toggle-toggle">
        {isDarkTheme ? (
          <svg
            className="themechangers"
            onClick={toggleTheme}
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 256 256"
          >
            <path d="M236 96a4 4 0 0 1-4 4h-20v20a4 4 0 0 1-8 0v-20h-20a4 4 0 0 1 0-8h20V72a4 4 0 0 1 8 0v20h20a4 4 0 0 1 4 4m-92-44h12v12a4 4 0 0 0 8 0V52h12a4 4 0 0 0 0-8h-12V32a4 4 0 0 0-8 0v12h-12a4 4 0 0 0 0 8m69.73 103.58a4 4 0 0 1 .71 4a92 92 0 1 1-118-118a4 4 0 0 1 5.29 4.54A93.18 93.18 0 0 0 100 64a92.1 92.1 0 0 0 92 92a93.18 93.18 0 0 0 17.91-1.74a4 4 0 0 1 3.82 1.32m-9.46 7.67A100 100 0 0 1 92.75 51.73a84 84 0 1 0 111.52 111.52" />
          </svg>
        ) : (
          <svg
            className="themechangers"
            onClick={toggleTheme}
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 256 256"
          >
            <path d="M120 40V16a8 8 0 0 1 16 0v24a8 8 0 0 1-16 0m72 88a64 64 0 1 1-64-64a64.07 64.07 0 0 1 64 64m-16 0a48 48 0 1 0-48 48a48.05 48.05 0 0 0 48-48M58.34 69.66a8 8 0 0 0 11.32-11.32l-16-16a8 8 0 0 0-11.32 11.32Zm0 116.68l-16 16a8 8 0 0 0 11.32 11.32l16-16a8 8 0 0 0-11.32-11.32M192 72a8 8 0 0 0 5.66-2.34l16-16a8 8 0 0 0-11.32-11.32l-16 16A8 8 0 0 0 192 72m5.66 114.34a8 8 0 0 0-11.32 11.32l16 16a8 8 0 0 0 11.32-11.32ZM48 128a8 8 0 0 0-8-8H16a8 8 0 0 0 0 16h24a8 8 0 0 0 8-8m80 80a8 8 0 0 0-8 8v24a8 8 0 0 0 16 0v-24a8 8 0 0 0-8-8m112-88h-24a8 8 0 0 0 0 16h24a8 8 0 0 0 0-16" />
          </svg>
        )}
      </div>
      <div
        className="custom-toggler"
        onClick={() => setExpanded(!expanded)}
        aria-controls="basic-navbar-nav"
      >
        {isDarkTheme ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
          >
            <path
              className="menu-toggler"
              d="M4.5 17q-.213 0-.356-.144Q4 16.712 4 16.5t.144-.356Q4.288 16 4.5 16h10.423q.213 0 .356.144t.144.357q0 .212-.144.356q-.143.143-.356.143zm14.438-1.258l-3.196-3.177Q15.5 12.323 15.5 12q0-.323.242-.565l3.196-3.158q.14-.14.345-.15q.204-.01.363.15q.16.16.16.354q0 .194-.16.354L16.573 12l3.073 3.035q.16.134.16.34t-.16.367q-.134.14-.34.14t-.368-.14M4.5 12.5q-.213 0-.356-.144Q4 12.212 4 12t.144-.356q.144-.143.356-.143h7.577q.212 0 .356.144t.144.357t-.144.356q-.144.143-.356.143zm0-4.5q-.213 0-.356-.144T4 7.499q0-.212.144-.356Q4.288 7 4.5 7h10.423q.213 0 .356.144q.144.144.144.357q0 .212-.144.356q-.143.143-.356.143z"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
          >
            <path
              className="menu-toggler"
              d="M4.5 17q-.213 0-.356-.144Q4 16.712 4 16.5t.144-.356Q4.288 16 4.5 16h10.423q.213 0 .356.144t.144.357q0 .212-.144.356q-.143.143-.356.143zm14.438-1.258l-3.196-3.177Q15.5 12.323 15.5 12q0-.323.242-.565l3.196-3.158q.14-.14.345-.15q.204-.01.363.15q.16.16.16.354q0 .194-.16.354L16.573 12l3.073 3.035q.16.134.16.34t-.16.367q-.134.14-.34.14t-.368-.14M4.5 12.5q-.213 0-.356-.144Q4 12.212 4 12t.144-.356q.144-.143.356-.143h7.577q.212 0 .356.144t.144.357t-.144.356q-.144.143-.356.143zm0-4.5q-.213 0-.356-.144T4 7.499q0-.212.144-.356Q4.288 7 4.5 7h10.423q.213 0 .356.144q.144.144.144.357q0 .212-.144.356q-.143.143-.356.143z"
            />
          </svg>
        )}
      </div>

      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto mr-5">
          {/* Set expanded to false on click to close the navbar */}
          <Nav.Link
            alt="skyfaze theme Home"
            as={Link}
            className="mr-4 nav-link-custom"
            to="/"
            onClick={() => setExpanded(false)}
          >
            Home
          </Nav.Link>
          <Nav.Link
            alt="skyfaze theme About"
            as={Link}
            className="mr-4 nav-link-custom"
            to="/about-us"
            onClick={() => setExpanded(false)}
          >
            About
          </Nav.Link>
          <Nav.Link
            alt="skyfaze theme Services"
            as={Link}
            className="mr-4 nav-link-custom"
            to="/services"
            onClick={() => setExpanded(false)}
          >
            Services
          </Nav.Link>
          <Nav.Link
            alt="skyfaze theme Projects"
            as={Link}
            className="mr-4 nav-link-custom"
            to="/projects"
            onClick={() => setExpanded(false)}
          >
            Projects
          </Nav.Link>
          <Nav.Link
            alt="skyfaze theme Contact"
            as={Link}
            className="mr-4 nav-link-custom"
            to="/contact"
            onClick={() => setExpanded(false)}
          >
            Contact-Us
          </Nav.Link>
          {isSignedIn && !isAdmin && (
            <Nav.Link
              alt="skyfaze theme dashboard"
              as={Link}
              className="nav-link-custom"
              to="/dashboard/bookingList"
              onClick={() => setExpanded(false)}
            >
              Dashboard
            </Nav.Link>
          )}
          {isAdmin && (
            <Nav.Link
              alt="skyfaze theme dashboard"
              as={Link}
              className="nav-link-custom"
              to="/dashboard/orderList"
              onClick={() => setExpanded(false)}
            >
              Admin Panel
            </Nav.Link>
          )}
        </Nav>
        {isSignedIn ? (
          <Navbar.Brand onClick={() => setExpanded(false)}>
            <img
              alt="skyfaze template"
              src={photoURL}
              width="30"
              height="30"
              className="d-inline-block align-top rounded-circle"
            />{" "}
            {name}
          </Navbar.Brand>
        ) : (
          <Button
            as={Link}
            to="/login"
            className="login-button"
            onClick={() => setExpanded(false)}
          >
            Login
          </Button>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default TopBar;
