import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../App";
import Loader from "../../../Components/Shared/Loader/Loader";
import {
  getUserOrder,
  addOrder,
  getSingleService,
} from "../../../Services/DashboardServices";
import Sidebar from "../Sidebar/Sidebar";
import BookingCard from "./BookingCard";
import toast from "react-hot-toast";

const BookingList = () => {
  const [bookings, setBookings] = useState([]);
  const [{ email }] = useContext(UserContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paymentSuccess = queryParams.get("paymentSuccess") === "true";
  const serviceId = queryParams.get("serviceId");
  const serviceName = queryParams.get("serviceName");

  useEffect(() => {
    const processPaymentSuccess = async () => {
      if (paymentSuccess && serviceId) {
        const newOrderData = {
          serviceId: serviceId,
          serviceName: serviceName,
          email: email,
        };

        try {
          await addOrder(newOrderData);
          toast.success("Payment successful and order placed!");
        } catch (error) {
          console.error("Failed to add order:", error);
          toast.error("Failed to process order.");
        }
      }
      fetchOrders();
    };

    const fetchOrders = async () => {
      try {
        const orders = await getUserOrder(email); // Fetch orders for the user
        const ordersWithServiceDetails = await Promise.all(
          orders.map(async (order) => {
            const serviceDetails = await getSingleService(order.serviceId);
            return {
              ...serviceDetails, // This contains the service details
              orderId: order.id, // Explicitly include the order's Firestore document ID as 'orderId'
            };
          }),
        );
        setBookings(ordersWithServiceDetails);
      } catch (error) {
        console.error("Error fetching orders with details:", error);
        toast.error("Failed to load booking details.");
      }
    };

    processPaymentSuccess();
  }, [email, location.search, paymentSuccess, serviceId, serviceName]);

  return (
    <section>
      <div className="row mr-0">
        <div className="col-md-2 col-sm-6 col-12">
          <Sidebar></Sidebar>
        </div>
        <div className="col-md-10 col-sm-12 col-12 d-flex justify-content-center">
          <div
            className={`row container justify-content-center ${bookings.length ? "" : "mt-5"}`}
          >
            {bookings.length ? (
              bookings.map((each) => (
                <BookingCard bookingInfo={each} key={each.id} />
              ))
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BookingList;
