import React from "react";
import { Badge, Card, Button } from "react-bootstrap";

const BookingCard = ({ bookingInfo }) => {
  const {
    serviceName,
    serviceDesc,
    serviceImg,
    servicePrice,
    status,
    color,
    orderId,
  } = bookingInfo;

  // Function to handle email button click
  const handleEmailClick = () => {
    const emailBody = `Hello,\n\nMy purchase ID is: ${orderId}\n\n[Purchase Support]`;
    window.location.href = `mailto:info@skyfaze.com?subject=Inquiry for Purchase ID: ${orderId}&body=${encodeURIComponent(emailBody)}`;
  };

  return (
    <div className="col-md-4 d-flex justify-content-center my-3">
      <Card border="light" className="shadow" style={{ width: "22rem" }}>
        <Card.Body>
          <div className="d-flex justify-content-between">
            <Card.Img
              variant="top"
              src={serviceImg}
              alt="Service"
              style={{ width: "80px" }}
            />
            <Badge variant={color}>{status}</Badge>
          </div>
          <Card.Title>{serviceName}</Card.Title>
          <Card.Text>{serviceDesc}</Card.Text>
          <Card.Text>Price: ${servicePrice}</Card.Text>

          <Card.Text style={{ color: "red" }}>Purchase ID: {orderId}</Card.Text>

          <Button variant="outline-primary" onClick={handleEmailClick}>
            Get Support
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default BookingCard;
