import { faOpenid } from "@fortawesome/free-brands-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { SideBarContext } from "../../../App";
import { addService } from "../../../Services/DashboardServices";
import Sidebar from "../Sidebar/Sidebar";

const AddService = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [imageUrl, setImageUrl] = useState("");
  const [btnEnable, setBtnEnable] = useState(true);
  const [sideBarInfo] = useContext(SideBarContext);
  const [serviceAdded, setServiceAdded] = useState(false);

  const openCloudinaryWidget = () => {
    window.cloudinary.openUploadWidget(
      {
        cloudName: "dj4vhf4ce",
        uploadPreset: "t3csk8f8",
        sources: ["local", "url", "camera"],
        cropping: true,
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          console.log("File uploaded successfully: ", result.info);
          setImageUrl(result.info.secure_url);
          setBtnEnable(true);
        }
      },
    );
  };

  const onSubmit = (data) => {
    if (!imageUrl) {
      toast.error("Please upload an image first.");
      return;
    }

    const parsedPrice = parseFloat(data.price);

    const serviceData = {
      serviceName: data.title,
      servicePrice: parsedPrice,
      serviceImg: imageUrl,
      serviceDesc: data.description,
    };

    addService(serviceData)
      .then(() => {
        setServiceAdded(true);
        toast.success("Service added successfully");
        setImageUrl("");
        reset();
      })
      .catch((err) => {
        toast.error("Service is not added");
        console.error(err);
      });
  };

  if (serviceAdded) {
    return (
      <section>
        <div className="row mr-0">
          <div
            className={`col-md-2 col-sm-6 col-12 d-md-block d-${
              sideBarInfo.isOpen ? "block" : "none"
            }`}
          >
            <Sidebar />
          </div>
          <div className="col-md-10 col-sm-12 col-12 text-center">
            <h1>Service Added Successfully!</h1>
            <button
              className="btn btn-primary"
              onClick={() => setServiceAdded(false)}
            >
              Add More Services
            </button>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section>
      <div className="row mr-0">
        <div
          className={`col-md-2 col-sm-6 col-12 d-md-block d-${
            sideBarInfo.isOpen ? "block" : "none"
          } `}
        >
          <Sidebar></Sidebar>
        </div>
        <div className="col-md-10 col-sm-12 col-12">
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="text-secondary ml-5 mt-5">
              {" "}
              <FontAwesomeIcon icon={faPlus} /> Add Services
            </h1>
            <h3
              className="d-none"
              style={{ zIndex: "1111" }}
              onClick={() => sideBarInfo.changeSideBar()}
            >
              <FontAwesomeIcon icon={faOpenid} />
            </h3>
          </div>
          <div className="row container mt-2 pt-5 ">
            <div className="col-md-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-12 mb-4">
                    <input
                      className="form-control border-0 bg-light"
                      placeholder="Service Title"
                      {...register("title", { required: true })}
                    />
                    {errors.title && (
                      <span className="text-dangen">* Title is required</span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-4">
                    <input
                      className="form-control  border-0 bg-light"
                      placeholder="Service Price"
                      {...register("price", { required: true })}
                    />
                    {errors.price && (
                      <span className="text-dangen">* Price is required</span>
                    )}
                  </div>
                  <div className="col-md-6">
                    {imageUrl && (
                      <div
                        className="image-preview"
                        style={{ marginBottom: "20px" }}
                      >
                        <img
                          src={imageUrl}
                          alt="Service"
                          style={{
                            width: "100%",
                            maxHeight: "300px",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    )}
                    <button
                      onClick={openCloudinaryWidget}
                      className="btn btn-info"
                    >
                      Upload Image
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 ">
                    <textarea
                      rows="5"
                      style={{ resize: "none" }}
                      className="form-control  border-0 bg-light"
                      placeholder="Description"
                      {...register("description", { required: true })}
                    />
                    {errors.description && (
                      <span className="text-dangen">
                        * Description is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 text-center">
                    <input
                      className={
                        btnEnable ? "btn btn-danger" : "btn btn-danger disabled"
                      }
                      type="submit"
                      value="Add Service"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddService;
