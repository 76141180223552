import React from "react";
import { Card } from "react-bootstrap";
import StarRatings from "react-star-ratings";

const TestimonialCard = ({ testimonialInfo }) => {
  const { name, post, review, photo, rating } = testimonialInfo;
  const child = { width: `350px`, height: `210px` };

  return (
    <div style={child} className="d-flex justify-content-center mt-5 mb-5 mx-3">
      <Card border="light" className="shadow" style={{ width: "18rem" }}>
        <div className="row mt-3">
          <div className="col-4 d-flex justify-content-center align-items-center">
            {" "}
            {/* Updated this line */}
            <img
              style={{ borderRadius: "50px", width: "50px" }}
              src={photo}
              alt="Review"
            />{" "}
            {/* Consider changing alt text to something more descriptive */}
          </div>
          <div className="col-8 text-left">
            <h6>{name}</h6>
            <small>{post}</small>
          </div>
        </div>
        <Card.Body>
          <Card.Text className="custom-review-card">{review}</Card.Text>
          <StarRatings
            rating={Number(rating)}
            starDimension="25px"
            starSpacing="5px"
            starRatedColor="#FFAC0C"
          />
        </Card.Body>
      </Card>
    </div>
  );
};

export default TestimonialCard;
