const firebaseConfig = {
  apiKey: "AIzaSyC3mXvGjgnAFmtEeEwSPrDwuwctrIRRHSU",
  authDomain: "skyfaze-themes.firebaseapp.com",
  projectId: "skyfaze-themes",
  storageBucket: "skyfaze-themes.appspot.com",
  messagingSenderId: "325698872839",
  appId: "1:325698872839:web:3d9d199b0bfe66992eaca2",
  measurementId: "G-5GLBE7XF7K",
};

export default firebaseConfig;
