import React from "react";
import "./CustomSvg.css"; // Make sure to import the CSS file where you've written the animation

const CustomSVG = () => {
  return (
    <svg
      id="a"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1160.31 942.95"
    >
      <g id="b" data-name="Layer 1-2">
        <g id="c" data-name="Market Flat 2">
          <g id="d" data-name="Artwork">
            <g id="e" data-name="Path 1">
              <path
                d="M1119.97,548.3c-1.69.04-3.05,1.4-3.08,3.08-2.77,25.23-8.78,50-17.87,73.71-2.55,6.67-5.35,13.23-8.39,19.69-.81,1.48-.32,3.33,1.11,4.22,1.47.84,3.35.35,4.22-1.11,11.14-23.62,19.17-48.59,23.89-74.28,1.34-7.37,2.42-14.78,3.22-22.23-.01-1.7-1.39-3.07-3.09-3.08h-.01Z"
                style={{ fill: "#fec3ad", strokeWidth: "0px" }}
              />
            </g>
            <g id="bubble" data-name="Ellipse 1">
              <circle
                cx="1042.95"
                cy="125.36"
                r="36.55"
                transform="translate(681.32 1111.6) rotate(-76.72)"
                style={{ fill: "#fee0d0", strokeWidth: "0px" }}
              />
            </g>
            <g id="g" data-name="Path 2">
              <path
                d="M198.24,363.66c21.87-8.43,39.1-25.78,47.39-47.7,5.95-16.05,5.81-36.17-6.53-48.03-10.94-10.52-28.14-11.41-42.91-7.93-12.8,3.1-24.76,9-35.02,17.26-10.31,8.23-18.82,18.48-25.01,30.13-6.07,11.46-11.5,26.49-9.89,39.65.56,4.58,1.78,9.43,5.45,12.52,3.63,3.06,8.68,4.2,13.13,5.47,15.77,4.48,32.2,5.13,48.03.43,1.81-.54,3.6-1.14,5.36-1.81h0Z"
                style={{ fill: "#fec3ad", strokeWidth: "0px" }}
              />
            </g>
            <g id="h" data-name="Path 3">
              <path
                d="M1075.86,937.52H63.67c-13.61-58.6-25.42-123.33-28.72-186.42-1.67-28.61-1.16-57.31,1.53-85.84,2.01-20.12,5.8-40.02,11.32-59.47,18.83-66.24,58.14-126.65,118.26-158.44,57.24-30.27,131.29-34.79,171.98-85.16,42.07-52.05,28.94-129.39,55.08-190.99,32.45-76.43,124.84-115.97,206.91-103.47,82.08,12.5,152.6,68.09,201.37,135.26,44.12,60.75,76.65,134.85,141.77,172.21,49.65,28.48,116.13,34.28,148.2,81.69,6.53,9.68,11.22,20.48,13.84,31.87,9.76,41.35-5.53,89.17-22.91,130.36-.26.62-.51,1.21-.76,1.81-22.4,52.67-46.93,113.02-22.44,164.75,12.27,25.92,35.5,45.5,47.28,71.65s-1.85,80.19-30.52,80.19Z"
                style={{ fill: "#fee0d0", strokeWidth: "0px" }}
              />
            </g>
            <g id="i" data-name="Ellipse 2">
              <ellipse
                cx="567.72"
                cy="937.52"
                rx="534.45"
                ry="5.43"
                style={{ fill: "#fec3ad", strokeWidth: "0px" }}
              />
            </g>
            <g id="j" data-name="Group 1">
              <g id="k" data-name="Path 4">
                <path
                  d="M946.37,612.74c-4.16-1.02-9-.04-11.75,3.24-1.94,2.5-2.77,5.68-2.29,8.81.5,3.09,1.69,6.03,3.49,8.6,2.32,3.84,5.77,6.87,9.87,8.69,4.15,1.63,9.23,1.29,12.61-1.6,3.03-2.85,4.46-7.02,3.81-11.14-.45-3.32-1.77-6.46-3.82-9.1-.66-.76-1.45-1.42-2.32-1.93-1.37-.93-2.57-2.07-3.95-2.99-1.73-1.18-3.64-2.05-5.66-2.57h.01Z"
                  style={{ fill: "#fe8d4c", strokeWidth: "0px" }}
                />
              </g>
              <g id="l" data-name="Path 5">
                <path
                  d="M1071.59,638.8c4.69-2.01,10.05-2.88,14.92-1.36s9.05,5.77,9.51,10.84c.41,4.52-2.13,8.95-5.71,11.75-3.71,2.65-8.01,4.37-12.52,5.04-3.99.93-8.13.97-12.14.14-4.05-.88-7.47-3.57-9.28-7.3-1.31-3.09-.87-6.64,1.15-9.32.89-1.16,1.92-2.2,3.08-3.09,1.62-1.19,3.27-2.37,4.97-3.45,1.91-1.25,3.92-2.33,6.01-3.25h.01Z"
                  style={{ fill: "#fe8d4c", strokeWidth: "0px" }}
                />
              </g>
              <g id="m" data-name="Path 6">
                <path
                  d="M995.41,652.04c-.78-3.23-.21-6.63,1.57-9.43,3.1-4.26,9.8-4.63,14.21-1.75s6.74,8.15,7.5,13.36c.65,3.17.22,6.47-1.22,9.36-1.16,1.92-3.05,3.28-5.24,3.77-1.55.57-3.26.55-4.8-.04-3.92-1.84-7.2-4.8-9.42-8.51-1.26-2.08-2.14-4.37-2.59-6.76h-.01Z"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
              <g id="n" data-name="Path 7">
                <path
                  d="M998.73,737.36c-1.12-12.4-3.5-24.65-7.1-36.57-6.92-22.88-18.17-44.21-33.15-62.84-.93-1.16-1.88-2.31-2.85-3.45-1.05-1.24-2.83.55-1.79,1.79,41.37,49.06,54.02,116.21,33.35,176.96-.52,1.54,1.91,2.2,2.44.67,8.33-24.61,11.42-50.68,9.09-76.56h0Z"
                  style={{ fill: "#fe8d4c", strokeWidth: "0px" }}
                />
              </g>
              <g id="o" data-name="Path 8">
                <path
                  d="M1009.92,662.37l-3.62,59.55-3.59,59.14c-.68,11.19-1.36,22.37-2.04,33.56-.1,1.62,2.43,1.62,2.53,0l3.62-59.56,3.59-59.14c.68-11.19,1.36-22.37,2.04-33.56.1-1.62-2.43-1.61-2.53,0h0Z"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
              <g id="p" data-name="Path 9">
                <path
                  d="M1063.02,656.04c-14.26,16-24.96,34.78-32.99,54.58-8.66,21.35-14.14,43.83-18.38,66.43-2.5,13.32-4.57,26.73-6.65,40.13-.25,1.59,2.19,2.28,2.44.67,3.65-23.56,7.3-47.17,13.25-70.29,5.42-21.06,12.79-41.72,23.52-60.7,5.81-10.39,12.72-20.12,20.6-29.04,1.07-1.22-.71-3.01-1.79-1.79h0Z"
                  style={{ fill: "#fe8d4c", strokeWidth: "0px" }}
                />
              </g>
              <g id="q" data-name="Path 10">
                <path
                  d="M996.67,788.96c6.79-11.28,15.01-22.07,18.28-34.83,2.67-10.42,1.83-21.43,3.69-32.03,2.84-15.8,11.48-29.97,24.23-39.72-4.93,18.63-9.91,37.54-9.75,56.81.1,12.9,2.5,25.95-.08,38.59-2.94,14.43-12.04,26.74-20.88,38.52-3.15,4.19-6.45,8.52-11.1,10.96-2.92,1.57-6.31,2.05-9.55,1.36-1.54-.3-2.98-1.01-4.15-2.06-.89-.91-.83-2.59-.95-3.77-.74-7.63,1.22-15.25,4.21-22.22,1.75-4.01,3.77-7.89,6.05-11.62h0Z"
                  style={{ fill: "#592e28", strokeWidth: "0px" }}
                />
              </g>
              <g id="r" data-name="Path 11">
                <path
                  d="M992.17,838.18c4.01-9.67,10.51-18.09,18.85-24.41,5.9-4.46,12.68-7.83,17.91-13.06,7.53-7.53,11.07-18.08,13.61-28.41s4.36-21.02,9.34-30.41c9.06-16.52,26.54-26.65,45.38-26.3-14.97,10.41-25.8,25.24-29.97,42.99-2.89,12.31-2.84,25.84-9.96,36.28-7.51,11.03-21.06,15.84-33.39,20.91s-27.77,9.69-31.78,22.41h.01Z"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
              <g id="s" data-name="Path 12">
                <path
                  d="M964.36,806.05c-5.04-14.39-5.67-29.88-7.71-44.99-4.15-30.59-14.2-60.08-29.59-86.84,11.65,2.57,20.33,12.23,26.98,22.13,12.58,18.76,20.85,40.08,24.23,62.42,2.05,13.7,2.23,27.64,4.12,41.36.77,5.9,1.99,11.73,3.66,17.44.84,2.84,1.84,5.62,2.99,8.35,1.17,2.75,2.68,5.27,4.01,7.93,1.06,2.11-1.99,2.5-3.42,2.06-2.34-.87-4.53-2.08-6.5-3.6-4.17-2.98-7.82-6.64-10.79-10.82-3.02-4.22-5.49-8.81-7.33-13.66-.23-.59-.45-1.19-.66-1.79h.01Z"
                  style={{ fill: "#592e28", strokeWidth: "0px" }}
                />
              </g>
              <g id="t" data-name="Path 13">
                <path
                  d="M974.49,801.49c-1.29-8.8-1.13-17.75.46-26.49,2.21-13.63,6.58-26.83,8.8-40.46,3.77-23.09,1.26-46.7-1.24-69.96,10.42,10.91,17.63,24.48,20.83,39.21,4.86,22.54,0,45.92-4.84,68.46l-3.75,17.42-3.75,17.43-3.73,17.35c-.15.7-.14,2.2-1.15,1.62-.78-.64-1.45-1.41-1.97-2.27-1.59-1.96-3-4.05-4.21-6.26-2.41-4.44-4.13-9.22-5.1-14.17-.13-.62-.25-1.26-.35-1.88h0Z"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
              <g id="u" data-name="Rectangle 1">
                <rect
                  x="945.2"
                  y="838.82"
                  width="110.47"
                  height="98.7"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
              <g id="v" data-name="Rectangle 2">
                <rect
                  x="940.75"
                  y="821.99"
                  width="119.37"
                  height="16.83"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
              <g id="w" data-name="Path 14">
                <path
                  d="M961.21,882.39l-3.61,5.78,3.61,5.52,3.61-5.52-3.61-5.78Z"
                  style={{ fill: "#592e28", strokeWidth: "0px" }}
                />
              </g>
              <g id="x" data-name="Path 15">
                <path
                  d="M980.82,882.39l-3.61,5.78,3.61,5.52,3.61-5.52-3.61-5.78Z"
                  style={{ fill: "#592e28", strokeWidth: "0px" }}
                />
              </g>
              <g id="y" data-name="Path 16">
                <path
                  d="M1000.43,882.39l-3.61,5.78,3.61,5.52,3.61-5.52-3.61-5.78Z"
                  style={{ fill: "#592e28", strokeWidth: "0px" }}
                />
              </g>
              <g id="z" data-name="Path 17">
                <path
                  d="M1020.04,882.39l-3.61,5.78,3.61,5.52,3.61-5.52-3.61-5.78Z"
                  style={{ fill: "#592e28", strokeWidth: "0px" }}
                />
              </g>
              <g id="aa" data-name="Path 18">
                <path
                  d="M1039.66,882.39l-3.61,5.78,3.61,5.52,3.61-5.52-3.61-5.78Z"
                  style={{ fill: "#592e28", strokeWidth: "0px" }}
                />
              </g>
              <g id="ab" data-name="Path 19">
                <path
                  d="M945.2,838.82v4.08l110.47,14.01v-18.09h-110.47Z"
                  style={{ fill: "#592e28", strokeWidth: "0px" }}
                />
              </g>
              <g id="ac" data-name="Path 20">
                <path
                  d="M1085.7,645.86c-8.56,1.34-16.61,4.97-23.27,10.51-1.25,1.04.54,2.82,1.79,1.79,6.36-5.23,14.02-8.63,22.16-9.86,1.6-.24.92-2.68-.67-2.44h-.01Z"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
              <g id="ad" data-name="Path 21">
                <path
                  d="M1011.78,662.26c-1.92-5.8-4.55-11.34-7.84-16.5-.87-1.37-3.06-.1-2.18,1.27,3.18,4.96,5.73,10.3,7.59,15.89.51,1.54,2.95.88,2.44-.67h-.01Z"
                  style={{ fill: "#fe8d4c", strokeWidth: "0px" }}
                />
              </g>
              <g id="ae" data-name="Path 22">
                <path
                  d="M956.66,636.09c-4.47-6.63-10.08-12.41-16.55-17.1-.6-.35-1.37-.14-1.73.45-.34.6-.14,1.37.45,1.73,6.12,4.44,11.42,9.92,15.65,16.19.9,1.34,3.09.08,2.18-1.27h0Z"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
            </g>
            <g id="af" data-name="Group 2">
              <g id="ag" data-name="Path 23">
                <path
                  d="M584.95,855.09v21.63c-3.21,1.99-6.58,3.72-10.06,5.18l-1.55.67c-4.39,1.89-8.84,3.65-13.34,5.27-4.51,1.62-9.05,3.11-13.63,4.46-1.88.57-3.77,1.08-5.66,1.59l-5.25-46.49,49.5,7.68h-.01Z"
                  style={{ fill: "#fe957c", strokeWidth: "0px" }}
                />
              </g>
              <g id="ah" data-name="Path 24">
                <path
                  d="M584.96,889.7s6.08-2.38,10.56.14,80.25,33.97,80.25,34.39,4.75,9.37-1.96,14.26h-141.49s-3.65-27.73,8.87-40.29,43.14-8.65,43.14-8.65l.63.14h0Z"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
              <g id="ai" data-name="Path 25">
                <path
                  d="M584.95,876.72v14.1s-5.78,21.59-43.67,8.18l-.58-5.11c1.9-.51,3.78-1.02,5.66-1.59,4.59-1.34,9.13-2.83,13.63-4.46,4.51-1.61,8.95-3.37,13.34-5.27l1.55-.67c3.49-1.46,6.85-3.19,10.06-5.18h.01Z"
                  style={{ fill: "#feb68f", strokeWidth: "0px" }}
                />
              </g>
              <g id="aj" data-name="Path 26">
                <path
                  d="M622.34,917.9c.83-4.2,2.49-8.19,4.87-11.75.34-.55,1.05-.73,1.61-.42.55.33.74,1.04.42,1.6-2.28,3.38-3.85,7.18-4.63,11.18-.17.63-.82.99-1.45.82s-.99-.82-.82-1.45h0v.02Z"
                  style={{ fill: "#fff", strokeWidth: "0px" }}
                />
              </g>
              <g id="ak" data-name="Path 27">
                <path
                  d="M600.81,910.58c2.09-4.46,4.18-8.91,6.27-13.37.33-.55,1.04-.74,1.6-.42.55.34.73,1.04.42,1.6l-6.27,13.37c-.33.55-1.04.74-1.6.42-.54-.34-.73-1.04-.42-1.6Z"
                  style={{ fill: "#fff", strokeWidth: "0px" }}
                />
              </g>
              <g id="al" data-name="Path 28">
                <path
                  d="M536.31,913.61c13.31-.28,24.94,8.94,27.71,21.95.31,1.48-1.96,2.11-2.27.63-2.49-12-13.19-20.5-25.44-20.23-.65-.02-1.16-.56-1.14-1.21.02-.62.52-1.12,1.14-1.14Z"
                  style={{ fill: "#fff", strokeWidth: "0px" }}
                />
              </g>
              <g id="am" data-name="Path 29">
                <path
                  d="M601.26,793.32c1.26,26.51-3.14,51.17-4.78,77.41-21.47,9.49-45.02,13.31-68.4,11.09-6.16-25.89-3.67-51.29-3.67-76.73,26.15-4.07,50.62-8.27,76.85-11.77h0Z"
                  style={{ fill: "#DC3545", strokeWidth: "0px" }}
                />
              </g>
            </g>
            <g id="an" data-name="Path 30">
              <path
                d="M701.41,133.58c-6.79,2.74-13.71,5.15-20.57,7.73-3.66-.8-7.32-1.59-10.98-2.38-.52-1.68-1.11-3.34-1.75-4.97-1.73-4.33-3.89-8.48-6.44-12.39-1.28-1.95-2.65-3.84-4.12-5.66-1.41-1.75-3.21-3.31-4.52-5.13-.94-1.35-.76-3.19.42-4.33,1.52-1.61,3.45-1.44,5.46-1.25,5.58.57,11.18.83,16.79.8.69-.01,1.39-.02,2.08-.05,9.43-.32,18.92-2.04,27.37-6.24,9.94-5.03,17.93-13.22,22.72-23.28,1.87,6.48,2.91,13.18,3.1,19.92.72,14.35-9.23,28.98-29.57,37.2v.03Z"
                style={{ fill: "#785035", strokeWidth: "0px" }}
              />
            </g>
            <g id="ao" data-name="Path 31">
              <path
                d="M727.88,76.45c-4.79,10.06-12.78,18.24-22.72,23.28-8.45,4.19-17.94,5.92-27.37,6.24-.69.02-1.39.04-2.08.05-5.61.03-11.21-.23-16.79-.8-2.01-.19-3.95-.37-5.46,1.25-1.18,1.14-1.36,2.98-.42,4.33,1.31,1.82,3.11,3.38,4.52,5.13,1.47,1.82,2.84,3.71,4.12,5.66,2.55,3.91,4.7,8.06,6.44,12.39.65,1.62,1.23,3.28,1.75,4.97-26-5.61-52.14-10.96-78.11-16.66-2.64-11.1-3.99-22.62-2.71-33.96s5.27-22.53,12.51-31.36,13.84-13.9,25.15-15.44c10.8-1.54,21.69,2.2,29.27,10.05,14.77-5,46.97.18,58.26,6.72,7.01,4.06,11.2,10.36,13.67,18.16h-.03Z"
                style={{ fill: "#42302a", strokeWidth: "0px" }}
              />
            </g>
            <g id="ap" data-name="Path 32">
              <path
                d="M592.63,127.08l-3.02,7.32c.47,6.7,1.29,13.36,2.47,19.97,2.66,14.36,7.65,28.52,16.4,40.19,5.61,7.3,12.36,13.65,19.98,18.81l25.21-61.11s-61.04-25.18-61.04-25.18Z"
                style={{ fill: "#fe957c", strokeWidth: "0px" }}
              />
            </g>
            <g id="aq" data-name="Path 33">
              <path
                d="M421.68,377.24c17.44-33.54,38-63.99,60.06-94.68-20.64-14.01-26.43-14.89-41.86-34.49,22.96-36.78,51.11-66.85,94.18-61.87,25.89,2.99,53.12,7.83,75.47,21.25,13.68,8.22,27.02,17.24,38.18,28.64s20.12,25.35,23.67,40.9c3.35,14.68,1.76,30.14-2.25,44.64s-10.78,28.73-17.13,42.38c-13.8.42-27.59-1.29-40.87-5.07.02.31-.81.58-.79.89l-33.29,80.71c-51.57-21.27-103.76-42.13-155.38-63.29h0Z"
                style={{ fill: "#fea13d", strokeWidth: "0px" }}
              />
            </g>
            <g id="ar" data-name="Path 34">
              <path
                d="M589.62,134.4l-28.21,68.38,2.88,5.41c10.01,18.79,31.94,27.84,52.29,21.57l5.86-1.81,6.02-14.59c-7.62-5.16-14.37-11.51-19.98-18.81-8.76-11.67-13.75-25.83-16.4-40.19-1.18-6.61-2-13.27-2.47-19.97h.01Z"
                style={{ fill: "#feb68f", strokeWidth: "0px" }}
              />
            </g>
            <g id="as" data-name="Path 35">
              <path
                d="M666.4,295.12c-8.27,22.6-16.55,45.2-24.82,67.81-1.3,3.56-6.74,1.31-5.44-2.24,8.27-22.6,16.54-45.2,24.82-67.81,1.3-3.56,6.74-1.31,5.44,2.24Z"
                style={{ fill: "#6d6e71", strokeWidth: "0px" }}
              />
            </g>
            <g id="at" data-name="Group 3">
              <g id="au" data-name="Path 36">
                <path
                  d="M351.4,844.56l-11.6,28.94c-1.13-.31-2.24-.67-3.32-1.06-.67-.24-1.33-.48-2-.74-5.46-2.11-10.68-4.78-15.59-7.97-4.91-3.19-9.49-6.86-13.67-10.96-1.66-1.64-3.26-3.35-4.8-5.12l7.9-28.64,43.09,25.55h0Z"
                  style={{ fill: "#fe957c", strokeWidth: "0px" }}
                />
              </g>
              <g id="av" data-name="Path 37">
                <path
                  d="M404.06,931.79l-62.91-.7-69.63-28.69s6.93-27.09,23.22-34.1,43.26,8.02,43.26,8.02l.53.36s6.53.06,9.75,4.06c1.86,2.31,14.84,15.14,31.68,31.82l24.11,5.11s3.5,8.81,0,14.12h-.01Z"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
              <g id="aw" data-name="Path 38">
                <path
                  d="M339.81,873.5l-1.69,4.22s-13.4,17.9-43.59-8.66l5.89-21.4c1.54,1.76,3.14,3.47,4.8,5.12,4.18,4.1,8.76,7.77,13.67,10.96,4.91,3.18,10.13,5.85,15.59,7.97.67.26,1.33.51,2,.74,1.08.39,2.18.76,3.32,1.06h0Z"
                  style={{ fill: "#feb68f", strokeWidth: "0px" }}
                />
              </g>
              <g id="ax" data-name="Path 39">
                <path
                  d="M346.79,910.86c5.03-5.02,11.11-8.88,17.8-11.29,1.43-.52,2.04,1.76.63,2.27-6.3,2.29-12.02,5.94-16.76,10.68-.47.44-1.22.42-1.66-.05-.43-.45-.42-1.16,0-1.61h0Z"
                  style={{ fill: "#fff", strokeWidth: "0px" }}
                />
              </g>
              <g id="ay" data-name="Path 40">
                <path
                  d="M333.36,895.76c5.63-4.85,12.67-7.76,20.09-8.3,1.51-.11,1.5,2.24,0,2.35-6.8.5-13.25,3.17-18.42,7.61-1.14.98-2.81-.68-1.66-1.66h0Z"
                  style={{ fill: "#fff", strokeWidth: "0px" }}
                />
              </g>
              <g id="az" data-name="Path 41">
                <path
                  d="M284.07,881.99c5.49,1.13,9.89,5.25,12.34,10.18,3.01,6.06,3.18,13.03,2.81,19.65-.09,1.51-2.44,1.52-2.35,0,.34-6.04.34-12.5-2.3-18.08-2.17-4.59-6.02-8.44-11.12-9.48-1.48-.3-.85-2.57.63-2.27h-.01Z"
                  style={{ fill: "#fff", strokeWidth: "0px" }}
                />
              </g>
              <g id="ba" data-name="Path 42">
                <path
                  d="M375.56,802.79c-3.56,21.13-11.87,41.17-24.3,58.61-20.5-3.9-40.06-11.75-57.57-23.11,4.15-15.46,7.81-31.42,11.96-46.88,23.57,3.64,46.35,7.74,69.91,11.38Z"
                  style={{ fill: "#DC3545", strokeWidth: "0px" }}
                />
              </g>
            </g>
            <g id="bb" data-name="Path 43">
              <path
                d="M710.33,113.75c-5.33,4.3-10.2,9.13-14.55,14.41-10.25,12.37-16.71,27.43-18.6,43.38l-10.19-6.85c-6.55,9.64-13.9,21.91-25.23,24.63-9.01,2.16-18.57-1.17-26.24-6.36-19.78-13.38-29.51-40.01-23.01-62.99,1.62-5.73-.45-13.29,2.21-18.61,2.13-4.46,5.65-8.11,10.03-10.41,4.43-2.27,9.63-2.47,14.22-.55,4.58,2.04,7.64,6.47,7.92,11.47.09,5.06-3.24,9.54-8.11,10.91,2.7,2.15,5.42,4.75,8.12,6.9,5.25-2.56,9.53-6.76,12.17-11.97.55-1.51,1.46-2.85,2.65-3.93,2.27-1.63,5.41-.6,8.01.41,19.15,7.4,40.07,10.11,60.6,9.55h0Z"
                style={{ fill: "#feb68f", strokeWidth: "0px" }}
              />
            </g>
            <g id="bc" data-name="Path 44">
              <path
                d="M590.61,135.22c1.6-.23,3.09.87,3.34,2.47.4,15.27,7.19,29.67,18.71,39.7,3.19,2.78,6.71,5.15,10.49,7.07,1.48.62,2.22,2.29,1.69,3.8-.59,1.51-2.29,2.26-3.8,1.69-15.06-7.66-26.22-21.26-30.8-37.52-1.28-4.51-1.99-9.17-2.1-13.86-.19-1.59.9-3.06,2.48-3.34h-.01Z"
                style={{ fill: "#fe957c", strokeWidth: "0px" }}
              />
            </g>
            <g id="bd" data-name="Path 45">
              <path
                d="M667.94,127.92c1.28.98,1.55,2.8.62,4.11l-4.91,6.63c-.97,1.31-2.81,1.58-4.11.62-1.31-.97-1.58-2.81-.62-4.11h0l4.91-6.63c.98-1.29,2.8-1.56,4.11-.62Z"
                style={{ fill: "#33251d", strokeWidth: "0px" }}
              />
            </g>
            <g id="be" data-name="Path 46">
              <path
                d="M658.44,115.25c7.13-.64,14.26,1.06,20.34,4.84,1.3.97,1.57,2.81.62,4.11-.99,1.27-2.8,1.54-4.11.62-.32-.2-.65-.39-.97-.58-.31-.18-.33-.19-.05-.03-.17-.09-.33-.18-.5-.26-.67-.34-1.36-.66-2.06-.95-.7-.29-1.41-.55-2.13-.78-.36-.12-.72-.23-1.08-.33-.07-.02-.61-.16-.74-.19-1.44-.33-2.9-.55-4.37-.66-1.35-.13-2.71-.12-4.06.03-1.61.24-3.1-.87-3.34-2.47s.87-3.1,2.47-3.34h-.02Z"
                style={{ fill: "#33251d", strokeWidth: "0px" }}
              />
            </g>
            <g id="bf" data-name="Path 47">
              <path
                d="M602.28,103.01c.61-.46,1.37-.68,2.13-.61l5.64.65c.01-.26.04-.52.05-.78v-.64c0-.27-.02-.54-.04-.81-.04-.7-.12-1.39-.22-2.08-.24-1.61.87-3.1,2.47-3.34s3.1.87,3.34,2.47h0c.86,5.92-.15,11.96-2.91,17.27-.31.72-.87,1.31-1.59,1.65-.71.3-1.5.31-2.22.04-1.32-.58-2.46-2.34-1.69-3.8.62-1.16,1.15-2.37,1.58-3.62.06-.18.11-.36.16-.54l-5.24-.6c-.78-.1-1.5-.5-2-1.11-1-1.28-.78-3.13.49-4.13h.01l.04-.02Z"
                style={{ fill: "#fe957c", strokeWidth: "0px" }}
              />
            </g>
            <g id="bg" data-name="Path 48">
              <path
                d="M633.66,149.98l11.95,17.7c-3.12,1.74-6.9,1.82-10.09.21-3.16-1.74-5.26-4.91-5.64-8.5-.37-3.57,1.04-7.09,3.77-9.42h.01Z"
                style={{ fill: "#fff", strokeWidth: "0px" }}
              />
            </g>
            <g id="bh" data-name="Ellipse 3">
              <circle
                cx="640.25"
                cy="134.67"
                r="7.76"
                style={{ fill: "#fe957c", strokeWidth: "0px" }}
              />
            </g>
            <g id="bi" data-name="Group 4">
              <g id="bj" data-name="Path 49">
                <path
                  d="M514.97,900.91v32.16h-105.69v-80.25l105.69,48.09Z"
                  style={{ fill: "#fe8d4c", strokeWidth: "0px" }}
                />
              </g>
              <g id="bk" data-name="Path 50">
                <path
                  d="M514.97,818.14v82.77l-105.69-48.09v-34.68h105.69Z"
                  style={{ fill: "#ec6641", strokeWidth: "0px" }}
                />
              </g>
              <g id="bl" data-name="Path 51">
                <path
                  d="M608.63,386.5v417.62H127.01v-176.86c6.11-.78,12.26-1.3,18.27-1.84,5.31,0,10.83-1.04,16.09-1.65,11.29-1.29,22.54-2.89,33.75-4.78,11.2-1.87,22.35-4.03,33.44-6.48,11.14-2.46,22.22-5.19,33.22-8.2,10.96-3,21.83-6.27,32.63-9.8,10.8-3.53,21.5-7.33,32.1-11.39,10.61-4.06,21.12-8.37,31.54-12.93s20.71-9.37,30.89-14.43,20.23-10.36,30.16-15.9c9.93-5.54,19.72-11.31,29.38-17.31,9.65-6,19.16-12.23,28.52-18.69,9.37-6.45,18.57-13.13,27.61-20.02,9.04-6.88,17.91-13.99,26.61-21.31,8.7-7.31,17.21-14.82,25.56-22.55,8.34-7.72,16.48-15.64,24.42-23.76,7.99-8.16,15.77-16.5,23.33-25.04,1.38-1.55,2.75-3.11,4.1-4.68h0Z"
                  style={{ fill: "#ec6641", strokeWidth: "0px" }}
                />
              </g>
              <g id="bm" data-name="Path 52">
                <path
                  d="M608.63,259.73v126.77c-1.36,1.57-2.73,3.13-4.11,4.69-7.57,8.53-15.34,16.88-23.33,25.04-7.94,8.11-16.08,16.03-24.42,23.76-8.33,7.72-16.85,15.23-25.56,22.55-8.71,7.31-17.57,14.41-26.6,21.3-9.04,6.9-18.24,13.57-27.61,20.02-9.36,6.46-18.87,12.69-28.53,18.69s-19.46,11.76-29.38,17.31c-9.92,5.54-19.97,10.84-30.16,15.9s-20.48,9.87-30.89,14.43c-10.4,4.57-20.91,8.88-31.54,12.93-10.6,4.06-21.3,7.86-32.1,11.39-10.8,3.53-21.68,6.8-32.63,9.8-11,3.01-22.08,5.75-33.22,8.2-11.1,2.45-22.24,4.61-33.44,6.48s-22.45,3.47-33.75,4.78c-5.26.62-10.78,1.65-16.09,1.65-6.01.54-12.17,1.06-18.27,1.84v-149.69c0-148.08,103.17-159.63,103.17-159.63l378.45-58.2h.01Z"
                  style={{ fill: "#fe8d4c", strokeWidth: "0px" }}
                />
              </g>
              <g id="bn" data-name="Path 53">
                <path
                  d="M522.16,415.24l-329.34,36.33-93.43,10.31c-8.93.99-9.03,15.12,0,14.12l329.34-36.33,93.43-10.31c8.93-.99,9.03-15.12,0-14.12Z"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
              <g id="bo" data-name="Path 54">
                <path
                  d="M549.12,450.09l-168.35,18.04-167.5,17.94-94,10.07c-3.73.4-3.77,6.29,0,5.88l168.35-18.04,167.5-17.94,94-10.07c3.73-.4,3.77-6.29,0-5.89h0Z"
                  style={{ fill: " #f7f8f9", strokeWidth: "0px" }}
                />
              </g>
              <g id="bp" data-name="Path 55">
                <path
                  d="M522.16,481.94l-329.34,36.33-93.43,10.31c-8.93.99-9.03,15.12,0,14.12l329.34-36.33,93.43-10.31c8.93-.99,9.03-15.12,0-14.12Z"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
              <g id="bq" data-name="Path 56">
                <path
                  d="M506.17,804.12v-353.16s.14-200.99,157.28-200.99,154.77,198.25,154.77,198.25v355.9h-312.05Z"
                  style={{ fill: "#fea13d", strokeWidth: "0px" }}
                />
              </g>
              <g id="br" data-name="Path 57">
                <path
                  d="M534.69,804.12v-353.16s.14-200.99,157.28-200.99,154.77,198.25,154.77,198.25v355.9h-312.05Z"
                  style={{ fill: "#592e28", strokeWidth: "0px" }}
                />
              </g>
              <g id="bs" data-name="Rectangle 3">
                <rect
                  x="116.52"
                  y="797.18"
                  width="389.65"
                  height="29.03"
                  style={{ fill: "#fe8d4c", strokeWidth: "0px" }}
                />
              </g>
              <g id="door" data-name="Rectangle 4">
                <rect
                  x="506.17"
                  y="797.18"
                  width="577.83"
                  height="29.03"
                  style={{ fill: "#fea13d", strokeWidth: "0px" }}
                />
              </g>
              <g id="bu" data-name="Path 58">
                <path
                  d="M375.79,473.2c6.9,0,12.5-5.59,12.51-12.49v-.07c-.34-65,0-361.39.12-405.22.01-5.06-4.08-9.16-9.13-9.18-.86,0-1.72.12-2.55.36l-28.85,8.28c-5.72,1.64-9.57,7-9.3,12.95l3.17,73.88c.24,5.57,4.95,9.88,10.51,9.64.91-.04,1.82-.2,2.69-.49l8.33-2.71v312.54c0,6.91,5.6,12.5,12.51,12.5h-.01Z"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
              <g id="co" data-name="Ellipse 4">
                <circle
                  cx="375.73"
                  cy="458.95"
                  r="4.89"
                  style={{ fill: "#fe8d4c", strokeWidth: "0px" }}
                />
              </g>
              <g id="bw" data-name="Path 59">
                <path
                  d="M367.35,71c-.44-1.56-2.05-2.47-3.62-2.06l-29.24,10.13c-1.54.46-2.45,2.06-2.05,3.62.44,1.56,2.05,2.47,3.62,2.06l29.24-10.13c1.54-.46,2.45-2.06,2.05-3.62Z"
                  style={{ fill: "#fe8d4c", strokeWidth: "0px" }}
                />
              </g>
              <g id="bx" data-name="Path 60">
                <path
                  d="M367.35,96.16c-.44-1.56-2.05-2.47-3.62-2.05l-29.24,10.13c-1.54.46-2.45,2.06-2.05,3.62.44,1.56,2.05,2.48,3.62,2.05,9.75-3.38,19.49-6.76,29.24-10.13,1.54-.46,2.45-2.06,2.05-3.62Z"
                  style={{ fill: "#fe8d4c", strokeWidth: "0px" }}
                />
              </g>
              <g id="by" data-name="Path 61">
                <path
                  d="M367.35,121.33c-.44-1.56-2.05-2.47-3.62-2.05l-29.24,10.13c-1.54.46-2.45,2.06-2.05,3.62.44,1.56,2.05,2.48,3.62,2.05l29.24-10.13c1.54-.46,2.45-2.06,2.05-3.62Z"
                  style={{ fill: "#fe8d4c", strokeWidth: "0px" }}
                />
              </g>
            </g>
            <g id="bz" data-name="Group 5">
              <g id="ca" data-name="Path 62">
                <path
                  d="M833.57,799.24c-10.98,1.76-21.86,4.08-32.6,6.95-14.51-6.29-29.69-10.95-45.23-13.87-28.68-5.78-58.06-7.87-86.47-14.84-1.06-.26-2.12-.53-3.17-.8-8.58-2.2-16.99-5-25.18-8.35-8.18-3.33-16.03-7.43-23.44-12.23-3.64-2.37-7.14-4.95-10.49-7.72-.57-.48-1.2-.98-1.87-1.51,9.31-4.95,19.25-8.63,29.54-10.93,21.65-4.44,44.1-.24,65.46,5.41,47.13,12.49,92.12,32,133.44,57.88h0Z"
                  style={{ fill: "#ec6641", strokeWidth: "0px" }}
                />
              </g>
              <g id="cb" data-name="Path 63">
                <path
                  d="M800.97,806.2c-70.69,18.91-135,61.45-179.52,119.6-.58-1.54-1.07-3.11-1.47-4.71-4.42-13.53-9.95-26.67-16.53-39.29-13.19-25.28-30.39-48.25-50.94-68.01-5.12-4.92-10.7-9.29-15.92-14.07-5.27-4.84-2.74-10.92,2.54-14.5,7.08-4.79,14.86-8.79,22.23-13.14,7.56-4.45,15.11-8.9,22.66-13.36,6.93-4.08,13.92-8.19,21.1-11.84.67.53,1.31,1.02,1.87,1.51,3.35,2.77,6.85,5.34,10.49,7.72,7.41,4.8,15.26,8.89,23.44,12.23,8.19,3.36,16.61,6.15,25.18,8.35,1.05.27,2.11.54,3.17.8,28.41,6.97,57.79,9.06,86.47,14.84,15.55,2.93,30.72,7.58,45.24,13.88h0Z"
                  style={{ fill: "#fe8d4c", strokeWidth: "0px" }}
                />
              </g>
              <g id="cc" data-name="Path 64">
                <path
                  d="M812.88,792.02c-67.99,16.5-129.92,51.94-178.59,102.2,1.68-38.81,1.4-77.67-.82-116.45-.33-5.77-4.97-8.82-10.43-7.94l-87.31,14.09c-4.43.71-6.8,6.17-5.75,10.13,1.23,4.69,5.76,6.46,10.13,5.75l77.39-12.49c2.17,44.17,1.81,88.43-1.07,132.57-.69,10.49,15.47,10.54,16.44.28,48.31-55.67,112.76-94.9,184.39-112.26,10.3-2.48,5.94-18.37-4.38-15.88h0Z"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
            </g>
            <g id="cd" data-name="Group 6">
              <g id="ce" data-name="Path 65">
                <path
                  d="M908.75,676.88c-82.49,14.65-164.42,30.82-246.9,45.48-6.93,1.22-14.83,2.24-20.32-2.15-3.97-3.14-5.61-8.31-7.05-13.16-4.18-14.05-5.91-30.9-9.24-45.94,40.71-18.34,85.35-26.22,129.87-22.93,34.2,2.6,69.26,11.7,102.12,1.88,22.56-6.73,41.24-21.58,58.37-38.11,1.51,25.18-.8,50.44-6.85,74.93Z"
                  style={{ fill: "#ec6641", strokeWidth: "0px" }}
                />
              </g>
              <g id="cf" data-name="Path 66">
                <path
                  d="M915.6,601.94c-17.12,16.53-35.81,31.38-58.37,38.11-32.85,9.81-67.92.72-102.12-1.88-44.53-3.29-89.17,4.59-129.87,22.93-.69-3.2-1.47-6.31-2.37-9.3,13.34-22.7,39.66-34.78,65.54-39.58,25.89-4.8,52.53-3.71,78.62-7.27,51.92-7.29,99.63-32.6,134.79-71.5,7.77,22.07,12.41,45.12,13.77,68.49h.01Z"
                  style={{ fill: "#fe8d4c", strokeWidth: "0px" }}
                />
              </g>
              <g id="cg" data-name="Path 67">
                <path
                  d="M901.12,527.91c-21.24,29.93-52.34,51.42-87.84,60.7-40.86,10.43-83.75,5.77-124.8,15.04-21.12,4.77-41.27,13.08-58.08,26.92-8.18,6.74,2.75,19.07,10.94,12.32,31.18-25.69,73.79-27.77,112.32-30.17,21.83-1.36,43.83-2.83,65.04-8.53,18.1-4.86,35.29-12.66,50.86-23.09,17.64-11.81,32.98-26.74,45.27-44.04,6.15-8.67-7.57-17.79-13.71-9.15h0Z"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
              <g id="ch" data-name="Path 68">
                <path
                  d="M892.86,541.38c-16.44,61.03-72.35,96.62-125.39,123.71-20.91-6.01-41.88-11.89-62.71-18.15-19.51-5.86-38.72-12.71-55.49-24.55-8.68-6.13-17.8,7.59-9.14,13.71,17.57,12.41,37.84,19.95,58.3,26.13,11.18,3.38,22.43,6.54,33.65,9.77,5.97,1.72,11.94,3.44,17.91,5.17,5.61,1.62,13.02,5.25,18.87,4.88,4.86-.31,10.3-4.39,14.55-6.62,5.35-2.8,10.65-5.67,15.92-8.61,9.89-5.55,19.56-11.47,28.94-17.86,17.82-11.88,33.95-26.12,47.94-42.33,14.78-17.41,26.29-37.77,32.25-59.88,2.75-10.2-12.81-15.61-15.57-5.36h-.03Z"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
              <g id="ci" data-name="Path 69">
                <path
                  d="M911.5,544.51c-1.55-4.21-7.4-5.45-11.06-3.65-4.37,2.14-5.18,6.91-3.65,11.06,14.13,38.72,16.19,80.81,5.91,120.72-2.64,10.22,12.92,15.64,15.58,5.36,11.43-44.15,9.06-90.73-6.77-133.5h0Z"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
            </g>
            <g data-name="Group 7">
              <g id="letter" data-name="Rectangle 5">
                <rect
                  x="761.47"
                  y="261.1"
                  width="374.67"
                  height="233.68"
                  rx="4.97"
                  ry="4.97"
                  transform="translate(-68.12 283.44) rotate(-16.41)"
                  style={{ fill: "#fe8d4c", strokeWidth: "0px" }}
                />
              </g>
              <g id="letter" data-name="Path 70">
                <path
                  d="M1074.05,210.39l-318.71,93.86c-11.3,3.33-6.15,20.9,5.17,17.57l318.71-93.86c11.3-3.33,6.15-20.9-5.17-17.57h0Z"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
              <g id="letter" data-name="Path 71">
                <path
                  d="M740.47,362.4l49.79,169.06c3.33,11.3,20.9,6.15,17.57-5.17l-49.79-169.06c-3.33-11.3-20.9-6.15-17.57,5.17Z"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
              <g id="letter" data-name="Path 72">
                <path
                  d="M1139.03,428.73l-333.73,98.28c-11.3,3.33-6.15,20.9,5.17,17.57l333.73-98.28c11.3-3.33,6.15-20.9-5.17-17.57Z"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
              <g id="letter" data-name="Path 73">
                <path
                  d="M1093.86,245.4l33.38,113.33c3.33,11.3,20.9,6.15,17.57-5.17l-33.38-113.33c-3.33-11.31-20.9-6.15-17.57,5.17h0Z"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
              <g id="letter" data-name="Path 74">
                <path
                  d="M769.35,348.88l185.06,64.83c9.48,3.32,19.98-.59,24.98-9.3l66.31-115.42-70.96,95.63-22.99,4.47-182.4-40.22h0Z"
                  style={{ fill: "#ec6641", strokeWidth: "0px" }}
                />
              </g>
              <g>
                <path
                  id="letter"
                  d="M1079.06,237.02c-3.28-3.08-9.98-3.61-12.95.39l-104.7,141.08-184.47-45.94c-11.46-2.85-15.84,14.93-4.4,17.78l190.32,47.4c2.89.72,6.98-.07,8.87-2.61l107.72-145.15c2.94-3.97,3.51-9.28-.39-12.95Z"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
            </g>
            <g id="cr" data-name="Group 8">
              <g id="cs" data-name="Path 76">
                <path
                  d="M812.68,791.82c55.64-14.52,102.96-57.73,122.46-111.83l-152.83-13.45c-42.28-3.72-84.96-7.43-127.05-1.95-42.09,5.49-84.12,21.01-114.24,50.92,24.12-2.89,50.97,3.55,73.96,11.41,22.98,7.87,44.81,18.71,67.12,28.31,41.98,18.06,85.82,27.49,130.59,36.58h-.01Z"
                  style={{ fill: "#fe8d4c", strokeWidth: "0px" }}
                />
              </g>
              <g id="ct" data-name="Path 77">
                <path
                  d="M929.91,679.58c-31.41,14.2-66.7,14.82-100.52,16.37-32.38,1.48-65.03,3.86-95.07,16.63l-75.27-63.03c-8.08-6.76-19.79,4.83-11.65,11.65l79.75,66.78c2.71,2.51,6.72,3.03,9.98,1.29,31.14-14.75,66.34-15.67,100.13-17.2,34.44-1.55,69.12-3.87,100.96-18.26,9.64-4.37,1.28-18.57-8.32-14.23h.01Z"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
              <g id="cu" data-name="Path 78">
                <path
                  d="M913.07,695.47c-24.51,35.66-58.49,63.77-98.12,81.15-9.68,4.27-1.3,18.47,8.32,14.23,42.11-18.77,78.13-48.92,104.03-87.06,5.99-8.78-8.28-17.04-14.22-8.32h-.01Z"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
              <g id="cv" data-name="Path 79">
                <path
                  d="M792.86,772.21c-66.02-1.15-124.25-37.44-185.76-56.72-17.7-5.64-35.92-9.52-54.39-11.57-4.46-.48-8.19,4.13-8.24,8.24-.05,4.84,3.83,7.77,8.24,8.24,65.48,6.99,122.57,44.17,185.55,60.49,17.83,4.74,36.16,7.36,54.61,7.81,10.6.18,10.61-16.3,0-16.48h0Z"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
            </g>
            <g id="cw" data-name="Group 9">
              <g id="cx" data-name="Path 80">
                <path
                  d="M656.93,738.24l-63.21,10.45c-7.47-16.27-14.18-32.88-20.11-49.81-16.39-46.77-26.84-95.41-31.09-144.78-1.68-19.46-2.37-38.99-2.08-58.52,1.23-.75,2.47-1.53,3.7-2.32,6.95-4.48,13.91-8.97,20.86-13.46,13.74-8.87,27.49-17.75,41.23-26.63,1.72-1.11,3.44-2.22,5.15-3.33-8.64,98.41,7,197.44,45.55,288.4h0Z"
                  style={{ fill: "#fe8d4c", strokeWidth: "0px" }}
                />
              </g>
              <g id="cy" data-name="Path 81">
                <path
                  d="M593.72,748.7c-19.67,3.26-39.35,6.51-59.02,9.75v-259.27c1.91-1.21,3.84-2.4,5.74-3.6-.29,19.53.41,39.06,2.08,58.52,4.25,49.37,14.7,98.02,31.09,144.78,5.93,16.9,12.63,33.5,20.11,49.82Z"
                  style={{ fill: "#ec6641", strokeWidth: "0px" }}
                />
              </g>
              <g id="cz" data-name="Path 82">
                <path
                  d="M647.15,707.31c-23.5-77.5-34.37-158.28-32.19-239.23.05-1.17-.16-2.35-.6-3.43,2.85-4.65,5.67-9.33,8.44-14.04,5.37-9.17-8.86-17.46-14.23-8.32-21.79,37.17-46.49,72.55-73.88,105.8v25.46c22.91-26.51,44.13-54.44,63.52-83.62.14,75.14,11.27,149.85,33.05,221.76,3.07,10.12,18.97,5.81,15.89-4.38h0Z"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
            </g>
            <g id="da" data-name="Path 83">
              <path
                d="M475.67,276.31l31-34.69c2.52-2.82,6.71,1.31,4.19,4.14l-31,34.69c-2.52,2.82-6.71-1.31-4.19-4.14h0Z"
                style={{ fill: "#ec6641", strokeWidth: "0px" }}
              />
            </g>
            <g id="db" data-name="Path 84">
              <path
                d="M565.03,344.48s1.68-1.96-.84-9.37-34.49-.62-34.49-.62l-68.12-39.64,14.4-15.88s-20.03-29.04-36.09-30.91c0,0-36.75,42.31-29.2,57.12,7.26,14.25,98.25,52.27,105.25,55.18,1.08,2.33,2.83,4.3,5.01,5.65,2.22,1.36,4.58,2.5,7.03,3.39,5.87,2.36,12.79,4.63,17.85,8.44,2.84,1.95,6.47,2.33,9.65,1,3.12-1.36,5.73-3.69,7.42-6.65,1.67-2.94,2.93-6.1,3.76-9.38.99-3.05,1.51-6.23,1.54-9.44.08-3.26-1.04-6.44-3.16-8.92v.03Z"
                style={{ fill: "#feb68f", strokeWidth: "0px" }}
              />
            </g>
            <g id="dc" data-name="Path 85">
              <path
                d="M564.12,343.19c-5.69-1.65-11.56-2.6-17.47-2.82-1.51-.06-1.51,2.3,0,2.35,5.71.21,11.37,1.13,16.85,2.73.63.16,1.27-.22,1.43-.85.16-.61-.2-1.24-.8-1.42h-.01Z"
                style={{ fill: " #a7a9ac", strokeWidth: "0px" }}
              />
            </g>
            <g id="bubble2" data-name="Path 86">
              <path
                d="M1059.32,158.13l-8.29-34.42c-1.24-5.15-9.19-2.97-7.94,2.19,2.77,11.47,5.53,22.94,8.3,34.42,1.24,5.16,9.19,2.97,7.94-2.18h-.01Z"
                style={{ fill: "#b14d31", strokeWidth: "0px" }}
              />
            </g>
            <g id="bubble1" data-name="Path 87">
              <path
                d="M1117.77,126.03l-24.51,32.57c-3.19,4.24,3.96,8.35,7.11,4.16l24.51-32.57c3.19-4.24-3.96-8.35-7.11-4.16Z"
                style={{ fill: "#b14d31", strokeWidth: "0px" }}
              />
            </g>
            <g id="df" data-name="Path 88">
              <path
                d="M507.85,354.52c-19.49-7.78-38.51-16.69-56.97-26.68-8.92-4.83-17.93-9.74-26.4-15.32-3.27-2.16-6.54-4.69-8.47-8.17-2.15-3.87-1.79-8.28-.39-12.34,1.73-4.99,4.99-9.25,8.03-13.5,3.13-4.38,6.08-8.88,7.63-14.1,1.08-3.64-4.59-5.19-5.68-1.56-2.8,9.4-10.81,16.08-14.64,25.01-2.03,4.73-3.11,9.94-1.83,15.03,1.3,4.45,3.97,8.37,7.62,11.22,3.85,3,7.93,5.7,12.2,8.06,4.66,2.77,9.36,5.47,14.11,8.1,9.57,5.31,19.29,10.32,29.16,15.04,11.18,5.35,22.53,10.31,34.05,14.89,1.57.42,3.18-.5,3.62-2.06.39-1.56-.51-3.16-2.06-3.62h.02Z"
                style={{ fill: "#fe957c", strokeWidth: "0px" }}
              />
            </g>
            <g id="dg" data-name="Path 89">
              <path
                d="M250.3,794.24H114.97c-3.79,0-3.79,5.88,0,5.88h135.33c3.79,0,3.79-5.88,0-5.88Z"
                style={{ fill: "#b14d31", strokeWidth: "0px" }}
              />
            </g>
            <g id="dh" data-name="Path 90">
              <path
                d="M323.3,794.24h-51.45c-3.79,0-3.79,5.88,0,5.88h51.45c3.79,0,3.79-5.88,0-5.88Z"
                style={{ fill: "#b14d31", strokeWidth: "0px" }}
              />
            </g>
            <g id="di" data-name="Group 10">
              <g id="tick" data-name="Ellipse 5">
                <circle
                  cx="199.95"
                  cy="76.45"
                  r="76.45"
                  style={{ fill: "#fe8d4c", strokeWidth: "0px" }}
                />
              </g>
              <g id="tick" data-name="Path 91">
                <path
                  d="M261.97,24.39c-2.63-3.65-8.76-.13-6.1,3.56,18.12,25.16,20.82,59.34,6.37,86.87-2.11,4.02,3.98,7.59,6.1,3.56,15.55-29.62,13.23-66.78-6.37-94h0Z"
                  style={{ fill: "#ec6641", strokeWidth: "0px" }}
                />
              </g>
              <g id="tick" data-name="Path 92">
                <path
                  d="M139.2,25.33c-8.88,9.44-14.76,21.3-16.89,34.09-.74,4.44,6.07,6.36,6.81,1.88,1.83-11.59,7.07-22.38,15.07-30.97,3.09-3.33-1.89-8.33-4.99-5Z"
                  style={{ fill: "#ec6641", strokeWidth: "0px" }}
                />
              </g>
              <g id="tick" data-name="Path 93">
                <path
                  d="M239.72,53.24c-15.67,15.67-31.33,31.34-47.01,47.01l-25.19-25.19c-3.75-3.75-9.58,2.07-5.82,5.82l27.96,27.96c1.12,1.21,2.9,1.55,4.38.84.76-.17,1.46-.55,2-1.11l49.49-49.49c3.76-3.76-2.06-9.58-5.82-5.83h0Z"
                  style={{ fill: "#b14d31", strokeWidth: "0px" }}
                />
              </g>
            </g>
            <g id="dn" data-name="Path 94">
              <path
                d="M1082.32,619.12c-3.8-1.22-7.41-2.95-10.73-5.14-18.6-12.34-27.06-36.83-23.41-58.85s17.99-41.3,36.08-54.4c6.55-4.72,13.58-8.73,20.97-11.97,9.75,41.35-5.53,89.17-22.91,130.36h0Z"
                style={{ fill: "#fec3ad", strokeWidth: "0px" }}
              />
            </g>
            <g id="do" data-name="Path 95">
              <path
                d="M45.25,744.73c-3.08,2.65-6.55,4.81-10.3,6.38-1.67-28.61-1.16-57.31,1.53-85.84,2.01-20.12,5.8-40.02,11.32-59.47,14.76,13.61,24.79,31.58,28.62,51.29,5.89,31.94-6.44,66.61-31.18,87.64h.01Z"
                style={{ fill: "#fec3ad", strokeWidth: "0px" }}
              />
            </g>
            <g id="dp" data-name="Path 96">
              <path
                d="M547.13,42.09c-17.51.22-34.9,2.78-51.73,7.61-16.82,4.83-32.93,11.85-47.92,20.88-8.51,5.16-16.62,10.95-24.25,17.33-1.19,1.21-1.19,3.15,0,4.36,1.22,1.17,3.14,1.17,4.36,0,26.26-21.72,57.82-36.08,91.45-41.59,9.29-1.52,18.68-2.33,28.09-2.43,3.96-.05,3.97-6.22,0-6.17h0Z"
                style={{ fill: "#fec3ad", strokeWidth: "0px" }}
              />
            </g>
            <g id="dq" data-name="Path 97">
              <path
                d="M590.6,45.49c-.61-.54-1.37-.85-2.18-.9l-13.2-1.73c-.82,0-1.6.32-2.18.9-1.2,1.2-1.2,3.16,0,4.36.61.54,1.37.85,2.18.9l13.2,1.73c.82,0,1.6-.32,2.18-.9,1.2-1.2,1.2-3.16,0-4.36h0Z"
                style={{ fill: "#fec3ad", strokeWidth: "0px" }}
              />
            </g>
            <g id="dr" data-name="Path 98">
              <path
                d="M58.91,518.2c-1.48-.82-3.34-.33-4.22,1.11-18.5,24.28-32.65,51.58-41.83,80.69C3.64,628.95-.67,659.23.09,689.59c.45,17.13,2.57,34.16,6.35,50.87.88,3.87,6.83,2.23,5.95-1.64-6.49-29.1-7.92-59.1-4.23-88.68,3.62-29.5,12.16-58.19,25.26-84.86,7.44-15.12,16.35-29.48,26.6-42.86.85-1.47.35-3.35-1.11-4.22h0Z"
                style={{ fill: "#fec3ad", strokeWidth: "0px" }}
              />
            </g>
            <g id="ds" data-name="Path 99">
              <path
                d="M88.51,490.31c-1.21-1.18-3.15-1.18-4.36,0-4.11,4.69-8.22,9.38-12.33,14.06-1.18,1.23-1.15,3.18.08,4.36,1.2,1.15,3.09,1.15,4.29,0l12.33-14.06c1.17-1.22,1.17-3.14,0-4.36h-.01Z"
                style={{ fill: "#fec3ad", strokeWidth: "0px" }}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CustomSVG;
